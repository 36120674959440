import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  RGBrandHeader,
} from 'react-rg-designsystem';

import useCart from '../hooks/useCart';
import { useBrandImages, applyBrandToStyle } from '../hooks/useBrand';

import ContractHeader from './ContractHeader';
import Floorplan from './Floorplan';
import Cart from './Cart';
import ContractSignature from './ContractSignature';

import '../style/ContractDocument.scss';

export default function ContractDocument({ forceFullSize, preloadedBrand }) {
  // Gather data
  const ref = useRef(null);
  const { dbAuthor, configuratorId } = useParams();
  const activeConfiguratorId = parseInt(configuratorId, 10);

  const {
    activeConfiguration,
    activeConfigurator,
    cartList,
  } = useCart(activeConfiguratorId);

  const {
    alt,
    logoOnBrandSurface,
  } = useBrandImages();

  // Load brand as quickly as possible
  // React-to-Print requires it to be preloaded
  if (preloadedBrand && ref.current) {
    applyBrandToStyle(preloadedBrand, ref.current.style);
  }

  // Validate data
  if (!activeConfigurator || !activeConfiguration) {
    // console.log('no active configurator');
    return null;
  }
  // console.log(activeConfiguration, activeConfigurator, cartList);

  // Render
  return (
    <div
      className="ContractDocument paper"
      ref={ref}
    >
      <RGBrandHeader
        src={logoOnBrandSurface}
        alt={alt}
      />
      <ContractHeader
        dbAuthor={dbAuthor}
        configurator={activeConfigurator}
        configuration={activeConfiguration}
      />
      <Cart
        cartList={cartList}
        forceFullSize={forceFullSize}
      />
      <ContractSignature />
      <Floorplan
        configurator={activeConfigurator}
        cartList={cartList}
      />
    </div>
  );
}
