import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useLocalization from '../hooks/useLocalization';
import useConfiguration from '../hooks/useConfiguration';
import useBrand from '../hooks/useBrand';
import dispatchConfiguratorPkg from '../data/actions/dispatchConfiguratorPkg';
import dispatchConfiguratorFiles from '../data/actions/dispatchConfiguratorFiles';
import dispatchPresets from '../data/actions/dispatchPresets';

import ContractDocument from '../components/ContractDocument';

import '../style/Contract.scss';

// Contract Page
// ------------
//
// The contract page is not used by users,
// but Artist could send the page as a link to marketers
// to give them direct access to the contract without having to print the page.
//
// The page also uses and previews the ContractDocument, which is used in the print functionality.

export default function Contract() {
  const dispatch = useDispatch();
  const translate = useLocalization();
  const activeConfiguration = useSelector((state) => state.activeConfiguration);

  // Gather data
  const { dbAuthor, configuratorId } = useParams();
  const activeConfiguratorId = parseInt(configuratorId, 10);
  useBrand(dbAuthor, activeConfiguratorId);

  // Init data
  useEffect(() => {
    dispatch(dispatchConfiguratorPkg(dbAuthor));
    dispatch(dispatchConfiguratorFiles(dbAuthor));
    dispatch(dispatchPresets(dbAuthor));
  }, [dbAuthor, dispatch]);

  // Init document title
  useEffect(() => {
    if (activeConfiguration) {
      document.title = `${activeConfiguration.code} - ${translate('tool.title')}`;
    }
  }, [activeConfiguration, translate]);

  // Load savestate
  useConfiguration();

  // Render
  return (
    <div
      className="Contract"
    >
      <ContractDocument />
    </div>
  );
}
