import React from 'react';

import {
  RGButtonEnums,
} from 'react-rg-designsystem';

import paths from '../data/paths';

import ToolbarHeader from './ToolbarHeader';
import OptionList from './OptionList';
import CartBar from './CartBar';

import '../style/SceneToolbar.scss';
import BrandToolbarDesktop from './BrandToolbarDesktop';

const getVisibleOptions = (scene, mode) => (
  mode === 'EDIT'
    ? scene.options
    : scene.options.filter((option) => (
      option.not_visible_in.findIndex((filteredScene) => (
        scene.id.toString() === filteredScene.id.toString()
      )) === -1))
);

function SceneToolbarMobile({
  scene,
  configuratorId,
  mode,
  sceneIndex,
  sceneCount,
}) {
  return (
    <div className="SceneToolbar mobile">
      <OptionList
        sceneId={scene.id}
        options={getVisibleOptions(scene, mode)}
        configuratorId={configuratorId}
        mode={mode}
        isMobile
      />
      <div>
        <CartBar
          emphasis={
            sceneIndex + 1 === sceneCount
              ? RGButtonEnums.emphasis.high
              : RGButtonEnums.emphasis.default
          }
        />
      </div>
    </div>
  );
}

function SceneToolbarDesktop({
  scene,
  configuratorId,
  mode,
}) {
  // Render
  return (
    <BrandToolbarDesktop
      className="SceneToolbar"
    >
      {
        scene && (
          <>
            <div>
              {
                mode === 'EDIT'
                  ? (
                    <a
                      href={`${paths.EDITOR_SCENE}/${scene.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ToolbarHeader
                        label={`${scene.displayed_title} (${scene.id})`}
                        description={scene.description}
                        mode={mode}
                      />
                    </a>
                  ) : (
                    <ToolbarHeader
                      label={scene.displayed_title}
                      description={scene.description}
                      mode={mode}
                    />
                  )
              }
            </div>
            <OptionList
              sceneId={scene.id}
              options={getVisibleOptions(scene, mode)}
              configuratorId={configuratorId}
              mode={mode}
              isMobile={false}
            />
          </>
        )
      }
    </BrandToolbarDesktop>
  );
}

export default function SceneToolbar({
  scene,
  configuratorId,
  mode,
  isMobile,
  sceneIndex,
  sceneCount,
}) {
  return (
    isMobile
      ? (
        <SceneToolbarMobile
          scene={scene}
          configuratorId={configuratorId}
          mode={mode}
          sceneIndex={sceneIndex}
          sceneCount={sceneCount}
        />
      ) : (
        <SceneToolbarDesktop
          scene={scene}
          configuratorId={configuratorId}
          mode={mode}
        />
      )
  );
}
