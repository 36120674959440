import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClone, faCopy } from '@fortawesome/pro-light-svg-icons';

import '../style/AccessCode.scss';

library.add(faClone, faCopy);

export default function AccessCode({
  code,
  translate,
}) {
  console.log(code);
  return (
    <div
      className="AccessCode"
    >
      {
        code === 'new'
          ? (
            <div className="codePlaceholder">
              <div />
              <div />
              <div />
            </div>
          ) : (
            <div className="content">
              <p>{translate('accessCode.your')}</p>
              <div className="code">
                <h2>{ code }</h2>
                <button
                  type="button"
                  aria-label="Access Code"
                  onClick={() => {
                    navigator.clipboard.writeText(code);
                  }}
                >
                  <FontAwesomeIcon
                    className="faIcon icon"
                    icon={faClone}
                  />
                </button>
              </div>
            </div>
          )
      }
    </div>
  );
}
