import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

import {
  RGContentSection,
  RGSidebarTop1Desktop,
  RGSidebarTop2Desktop,
} from 'react-rg-designsystem';

import dispatchConfiguratorPkg from '../data/actions/dispatchConfiguratorPkg';
import dispatchConfiguratorFiles from '../data/actions/dispatchConfiguratorFiles';
import useConfiguration from '../hooks/useConfiguration';

import MobilePreviews from '../components/MobilePreviews';
import ImageStructure from '../components/ImageStructure';
import ConfiguratorList from '../components/ConfiguratorList';
import PriceDataDownload from '../components/PriceDataDownload';

import '../style/Editor.scss';

library.add(faPaperPlane);

// Editor Page
// ------------
// This page displays various tools for different users.
//
// Artists will use most tools such as the mobile previews and
// detailed reports on the mapping of images to the strapi setup.
//
// Marketers and Real Estate Developers will use features related to pricing and
// the orders of buyers.
//
// konfigurator.tend.ch/edit/author
// author - the name of the strapi user (part in front of the @)

export default function Editor() {
  const { dbAuthor } = useParams();
  const configuratorPkg = useSelector((state) => state.configuratorPkg);
  const configuratorFiles = useSelector((state) => state.configuratorFiles);
  const [activeConfigurator, setActiveConfigurator] = useState(configuratorPkg);
  const dispatch = useDispatch();

  // Init data
  useEffect(() => {
    // Write version to console
    // eslint-disable-next-line no-console
    console.log(`Version: ${process.env.REACT_APP_VERSION}`);

    dispatch(dispatchConfiguratorPkg(dbAuthor));
    dispatch(dispatchConfiguratorFiles(dbAuthor));

    // // Start refresh timer
    // setInterval(
    //   () => {
    //     dispatch(dispatchConfiguratorPkg(dbAuthor));
    //     dispatch(dispatchConfiguratorFiles(dbAuthor));
    //   },
    //   2000,
    // );
  }, [dbAuthor, dispatch]);

  // Use a configuration
  useConfiguration();

  // Fetch error handling
  useEffect(() => {
    if (configuratorPkg.isError) throw new Error('Error fetching configurator package');
    if (configuratorFiles.isError) throw new Error('Error fetching configurator files');
  }, [configuratorPkg, configuratorFiles]);

  // // Handle events
  // const pushChanges = async () => {
  //   const response = await axios
  //     .put(`${paths.API_CONFIGURATOR}/configurator/push/${dbAuthor}`)
  //     .catch(() => alert('Dieser Dienst ist momentan nicht verfügbar.'));
  // eslint-disable-next-line max-len
  //   alert(`Die Änderungen wurden zur Veröffentlichung übergeben.\n\nErgebnis: ${response.data.message}`);
  // };
  // const handlePushChanges = (e) => {
  //   e.preventDefault();
  //   pushChanges();
  // };

  // Render
  return (
    <div
      className="Editor"
    >
      <RGSidebarTop1Desktop
        filterLabel="Konfigurator"
        filterValue={dbAuthor}
      />
      <RGSidebarTop2Desktop
        labelParent="Konfigurator bearbeiten"
      />
      <RGContentSection>
        <MobilePreviews
          scenes={activeConfigurator && activeConfigurator.scenes}
        />
      </RGContentSection>
      <RGContentSection>
        <ImageStructure />
      </RGContentSection>
      <RGContentSection>
        <PriceDataDownload />
      </RGContentSection>
      <RGContentSection>
        {
          configuratorPkg.isSuccess
            ? (
              <ConfiguratorList
                dbAuthor={dbAuthor}
                configuratorPkg={configuratorPkg.data}
                setActiveConfigurator={(configurator) => setActiveConfigurator(configurator)}
              />
            )
            : (
              <span className="loading">lädt...</span>
            )
        }
      </RGContentSection>
      {/* <RGContentSection>
        <RGButton
          label="Änderungen veröffentlichen"
          icon={faPaperPlane}
          onClick={handlePushChanges}
        />
      </RGContentSection> */}
    </div>
  );
}
