import React, { useRef } from 'react';
import { animated } from '@react-spring/web';

import RGFormContainer from './FormContainer';
import RGCard from './Card';

import useGestureCardScroller from '../hooks/useGestureCardScroller';
import '../../style/components/CardScroller.scss';

export interface RGCardScrollerProps {
  keys: string[],
  children: any,
  className?: string,
  autoFocus?: boolean,
  draggableObject?: any,
  focusedDetails?: any,
  // onClickCard?: (key: string) => void,
  // onOpenCard?: (isOpen: boolean) => void,
  onOpenCard?: (key: string) => void,
  onFocusCard?: (key: string) => void,
}

export default function RGCardScroller(props: RGCardScrollerProps) {
  // Validate props
  if (!props.keys)
  {
    throw new Error('RGCardScroller: keys is required');
  }
  if (!props.children)
  {
    throw new Error('RGCardScroller: children is required');
  }
  if (props.keys.length !== props.children.length) {
    throw new Error('Keys and children must be the same length');
  }

  // Initialize state
  const draggableObject = useRef<HTMLDivElement>(null);
  const scrolledObject = useRef<HTMLDivElement>(null);
  const focusedIndex = useRef<number>(0);
  
  // Attributes
  const cardCount = props.keys.length;
  const deadzone = 20;
  const softDragLimits = 40;
  const minCardHeight = scrolledObject.current ? scrolledObject.current.clientHeight : 0;
  const maxCardHeight = window.innerHeight * 0.5;
  const minOpenDistance = window.innerHeight * 0.25;

  // CSS settings
  const cardPadding = 20;
  const containerPaddingSides = 40;

  // Calculate targets
  const cardWidth = window.innerWidth - (containerPaddingSides * 2);
  const cardDistance = cardWidth + cardPadding;
  const maxOffsetX = cardDistance * (cardCount - 1);
  // console.log(cardWidth, cardDistance, maxOffsetX);
  // console.log(window.innerWidth, containerPaddingSides);

  // Event handlers
  const handleClickCard = (e: any, key: string) => {
    // console.log('handleClickCard', key);
    e.preventDefault();
    props.onOpenCard?.(key);
  };
  const handleFocusCard = (index: number) => {
    props.onFocusCard?.(props.keys[index]);
    focusedIndex.current = index;
  };
  const handleOpenCard = (isOpen: boolean) => {
    // Try to open the focused index
    // console.log('handleOpenCard', isOpen, focusedIndex.current);
    props.onOpenCard?.(
      isOpen && props.keys.length > focusedIndex.current
        ? props.keys[focusedIndex.current]
        : ''
    );
  };

  // Can it be dragged open?
  const hasCardDetails : boolean = props.focusedDetails;
  const cardsCanBeOpened : boolean = props.onOpenCard !== undefined || hasCardDetails;

  // Handle Gestures
  const {
    stylesX,
    stylesY,
    isDragging,
    showDetails,
    // resetScroll,
  } = useGestureCardScroller(
    deadzone,
    cardDistance,
    maxOffsetX,
    maxCardHeight,
    minCardHeight,
    minOpenDistance,
    handleFocusCard,
    handleOpenCard,
    draggableObject,
    hasCardDetails,
    softDragLimits,
  );

  // Build cards
  const cards = props.children.map((child: any, index: number) => (
    <RGCard
      key={props.keys[index]}
      blockInteractions={isDragging}
      isDraggable={cardsCanBeOpened}
      onClick={
        cardsCanBeOpened && !props.autoFocus
          ? (e : any) => handleClickCard(e, props.keys[index])
          : undefined}
    >
      { child }
    </RGCard>
  ));

  // Build details card
  const detailsCard = props.focusedDetails
    ? (
      <RGCard
        className="detailsCard"
        blockInteractions={isDragging}
        style={showDetails ? { opacity: 1 } : { opacity: 0 }}
      >
        { props.focusedDetails }
      </RGCard>
    ) : null;

  // Render
  return (
    <div
      className={`RGCardScroller ${props.className} ${props.autoFocus ? 'autoFocus' : 'scrollList'}`}
      ref={props.autoFocus ? draggableObject : null}
    >
      <RGFormContainer>
        {
          props.autoFocus
            ? (
              <animated.div
                className="movable"
                style={ stylesY }
              >
                <animated.div
                  className="scrollable"
                  style={ stylesX }
                  ref={scrolledObject}
                >
                  { cards }
                </animated.div>
                { detailsCard }
              </animated.div>
            ) : (
              <>{ cards }</>
            )
        }
      </RGFormContainer>
    </div>
  );
}
