// Basic reducer for fetching data from any API.
//
// This reducer can be used on its own or as a base for other reducers.
// The reducer also produces a consistent structure for the redux store.

export const getDefaultFetchState = () => (
  JSON.parse(JSON.stringify(
    {
      data: [],
      isFetching: false,
      isSuccess: false,
      hasData: false,
      isError: false,
    },
  ))
);

export default function baseFetchReducer(
  name, // name used for the action types
  state, // state to modify
  action, // action to process (carries the payload)
  // rejectionCallback = null,
) {
  switch (action.type) {
    case `FETCH_${name}_PENDING`:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case `FETCH_${name}_FULFILLED`:
      // console.log('recipes:');
      // console.log(action.payload.data);
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        hasData: true,
        isError: false,
        data: action.payload.data,
      };
    case `FETCH_${name}_REJECTED`:
      // this is an anti-pattern. instead react to a bad response in the component
      // if (rejectionCallback !== null) {
      //   rejectionCallback(state);
      // }
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        hasData: false,
        isError: true,
      };
    default:
      return {
        ...state,
      };
      // throw new Error(action.type);
  }
}
