import { useEffect, useState, useRef } from 'react';

// const equalStacks = (a, b) => (
//   a.map((item) => item.path).join('') === b.map((item) => item.path).join('')
// );

export const emptyStack = {
  key: 'none',
  stack: [],
};

export default function useImageStackCache(stack) {
  const desired = useRef({ ...emptyStack });
  const cacheCounter = useRef(0);
  const [cache, setCache] = useState({ ...emptyStack });

  const handleReady = () => {
    cacheCounter.current += 1;
    if (cacheCounter.current === desired.current.stack.length) {
      // console.log(`Loaded ${cacheCounter.current} images`);
      setCache(desired.current);
    }
  };

  const handleLoad = (image) => {
    // Try to use the image synchronously
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.drawImage(image, 0, 0);
    handleReady();
  };

  useEffect(() => {
    if (stack.key !== desired.current.key) {
      // Apply new stack
      cacheCounter.current = 0;
      desired.current = { ...stack };

      // Start caching
      const newCache = {};
      stack.stack.forEach((file) => {
        newCache[file.path] = new Image();
        newCache[file.path].onload = () => { handleLoad(newCache[file.path]); };
        newCache[file.path].src = file.fullPath;
      });
    }
  }, [stack, handleLoad]);

  return cache;
}
