import React from 'react';

import { QRCodeSVG } from 'qrcode.react';

import '../style/QRCode.scss';

export default function QRCode({ qrLink, textLink }) {
  return (
    <div
      className="QRCode"
    >
      <QRCodeSVG
        value={qrLink}
      />
      <p className="fine">{textLink}</p>
    </div>
  );
}
