import React, { useState } from 'react';
import {
  config,
  animated,
  useTransition,
} from '@react-spring/web';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronUp,
  faChevronDown,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleChevronUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../style/components/BottomCard.scss';
import ContainerButton from './ContainerButton';
import useFullHeight from '../hooks/useFullHeight';

library.add(
  faChevronUp,
  faChevronDown,
  faCircleChevronUp,
);

export interface RGBottomCardProps {
  className: string;
  minimizedContent: any[],
  detailsContent: any[],
  isMobile: boolean,
  emphasis?: string;
  onOpen?: () => void;
}

export default function RGBottomCard(props : RGBottomCardProps) {
  const [isOpen, setIsOpen] = useState(false);
  const fullHeight = useFullHeight();

  // Animate
  const detailTransitions = useTransition(isOpen, {
    from: {
      transform: 'translateY(100%)',
      // opacity: 0,
    },
    enter: {
      transform: 'translateY(0%)',
      // opacity: 1,
    },
    leave: {
      transform: 'translateY(100%)',
      // opacity: 1,
    },
    config: {
      ...config.stiff,
      // tension: 100,
      friction: 30,
      // velocity: 0.1,
      clamp: true,
      // bounce: 1,
    },
  });
  const backdropTransitions = useTransition(isOpen, {
    from: {
      opacity: 0.0,
    },
    enter: {
      opacity: 1.0,
    },
    leave: {
      opacity: 0.0,
    },
    config: config.stiff,
  });

  // Event Handlers
  const handleOpen = () => {
    // Open
    setIsOpen(true);

    // Forward
    if (props.onOpen) {
      props.onOpen();
    }
  };

  // Render
  return (
    <div
      className={`
      RGBottomCard
      ${isOpen ? 'open' : 'closed'}
      ${props.isMobile ? 'mobile' : 'desktop'}
      ${props.emphasis ? props.emphasis : 'default'}-emph
      ${props.className || ''}
      `}
    >
      {
        // Open Button
        isOpen ? (
          <div className="openSpacer" />
        ) : (
          <button
            className="openButton"
            type="button"
            onClick={handleOpen}
          >
            { props.minimizedContent }
            <FontAwesomeIcon
              className="faIcon icon on-elevation"
              icon={props.emphasis === 'high' ? faCircleChevronUp : faChevronUp}
            />
          </button>
        )
      }
      {
        // Backdrop
        backdropTransitions((styles, item) => item && (
          <animated.div
            className="backdrop"
            style={{
              ...styles,
              height: `${fullHeight}px`,
            }}
          />
        ))
      }
      {
        // Details
        detailTransitions((styles, item) => (
          item && (
            <animated.div
              className="details"
              style={{
                ...styles,
                height: `calc(${fullHeight}px - 10px)`,
              }}
              // style={{ transform: 'translateY(calc(100% - 52px))' }}
            >
              <ContainerButton
                icon={faChevronDown}
                onClick={() => { setIsOpen(false); }}
              />
              <div className="content">
                { props.detailsContent }
              </div>
            </animated.div>
          )
        ))
      }
    </div>
  );
}
