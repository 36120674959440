import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Home from './routes/Home';
import Viewer from './routes/Viewer';
import Editor from './routes/Editor';
import Contract from './routes/Contract';
import ErrorPage from './pages/ErrorPage';
import './style/App.scss';

function App() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorPage}>
        {/* <BaseLayout /> */}
        <Routes>
          <Route exact path="" element={<Home />} />
          <Route exact path="/view/:dbAuthor/:configuratorId" element={<Viewer />} />
          <Route exact path="/edit/:dbAuthor" element={<Editor />} />
          <Route exact path="/contract/:dbAuthor/:configuratorId" element={<Contract />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
