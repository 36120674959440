import { utils, writeFile } from 'xlsx-js-style';

// XLSX export
//
// Due to bad UX of Excel and CSV, most users are unable to open CSV files.
// Therefore, we use the XLSX format, which is the native Excel format.

const exportToXlsx = (
  tableNames, // the names of the tables
  tableDatasets, // array of datasets, each dataset represents a table
  fileName, // path to the file without extension
  applyStyle, // optional function to apply style to worksheet
) => {
  // Create workbook
  const workbook = utils.book_new();

  // Add tables
  for (let i = 0; i < tableDatasets.length; i += 1) {
    const tableDataset = tableDatasets[i];
    const worksheet = utils.json_to_sheet(
      tableDataset,
    );

    // Stylize?
    let styledWorksheet;
    if (applyStyle) {
      styledWorksheet = applyStyle(worksheet, i);
    }

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, styledWorksheet, tableNames[i]);
    // console.log(wb, ws, workbook, styledWorksheet);
  }

  // Save
  const fileExtension = '.xlsx';
  writeFile(workbook, fileName + fileExtension);
};
export default exportToXlsx;
