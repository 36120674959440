/* eslint-disable no-unused-vars */
import { getSelectedVariant } from './variant';

// -------------------------------------------------------------------------------------------

// Image Stacks
// ------------
// The image stack is the list of images that are displayed in the viewer.
// The images will be stacked on top of each other to create the final visual.
//
// The selection of the right images can be quite complex.
// Please checkt the documentation for details.

// -------------------------------------------------------------------------------------------

function filterFilesByApartmentNr(
  files,
  apartmentNr,
) {
  // console.log(files);
  if (!apartmentNr) {
    return [];
  }

  return files.filter((file) => (file.filePrefix === apartmentNr));
}

// -------------------------------------------------------------------------------------------

function filterFilesByScene(
  files,
  scene,
  viewpoint,
) {
  return files.filter((file) => (
    file.scene
    && file.viewpoint
    && file.scene.id === scene.id
    && file.viewpoint.id === viewpoint.id
  ));
}

// -------------------------------------------------------------------------------------------

function checkAssignmentsMatchesSelection(
  assignments,
  activeSelections,
) {
  // Search assignments for mismatch
  for (let iAssignment = 0; iAssignment < assignments.length; iAssignment += 1) {
    const assignment = assignments[iAssignment];

    // Get the selection for the assignment
    const selection = activeSelections.find((sel) => (
      sel.optionId.toString() === assignment.option.id.toString()
    ));

    // Option disabled?
    if (selection) {
      // Check if the selection is available for this assignment
      if (assignment.variants
        .findIndex((variant) => (variant.id.toString() === selection.variantId.toString())) === -1
      ) {
        return false;
      }
    }
  }

  return true;
}

// -------------------------------------------------------------------------------------------

/// Return whether file matches the selections
/// The file should only be displayed if all given selections match the defined assignments
function checkFileMatchesSelection(
  file,
  activeSelections,
) {
  /*
   * Currently only one set of assignments is supported
   * For more complex combinations of assignments,
   * the API would need to deliver an array of all possible assignments.
   *
   * Here we could easily expand to check if any of the assignments matches the selection
   */
  return checkAssignmentsMatchesSelection(file.assignments, activeSelections);
}

// -------------------------------------------------------------------------------------------

function filterFilesBySelection(
  files,
  options,
  configuration,
) {
  // What is currently selected?
  const selections = options.map((option) => ({
    optionId: option.id,
    variantId: getSelectedVariant(option, configuration).id,
  }));

  // Filter the files
  return files.filter((file) => (
    checkFileMatchesSelection(file, selections)
  ));
}

// -------------------------------------------------------------------------------------------

function getLayerOfAssignments(assignments, optionLayers) {
  const layers = [];
  for (let iAssignment = 0; iAssignment < assignments.length; iAssignment += 1) {
    const assignment = assignments[iAssignment];

    // Only care about the layer if its actually visible
    if (!assignment.isDynamicDependency) {
      const optionId = assignment.option.id;

      // Try to find the option in the layers
      const layerIndex = optionLayers.findIndex((layer) => (
        layer.options.findIndex((option) => (option.id.toString() === optionId.toString())) !== -1
      ));

      // Add the layer
      if (layerIndex !== -1) {
        layers.push(layerIndex);
      }
    }
  }

  // Unassigned layers are at the bottom
  if (layers.length === 0) {
    // console.log('Unassigned layer', assignments);
    return optionLayers.length;
  }

  // console.log(layers, assignments);
  return Math.min(...layers);
}

// -------------------------------------------------------------------------------------------

function sortImageStack(imageStack, viewpoint) {
  const sortedStack = [...imageStack];

  // Add the layers to the files
  for (let iFile = 0; iFile < sortedStack.length; iFile += 1) {
    const file = sortedStack[iFile];
    sortedStack[iFile].layer = getLayerOfAssignments(
      file.assignments,
      viewpoint.option_layers,
    );
  }

  // Sort by layer
  sortedStack.sort((a, b) => (b.layer - a.layer));

  // console.log(sortedStack);
  return sortedStack;
}

// -------------------------------------------------------------------------------------------

// Return the image stack for the given viewpoint and current configuration
// An optionFilter can be applied to ignore certain options
export default function getImageStack(
  files,
  apartmentNr,
  scene,
  viewpoint,
  configuration,
  optionFilter = (options) => options,
) {
  let imageStack = [];

  // Validate data
  if (!scene || !viewpoint) {
    return imageStack;
  }

  // // Debug !!!!!!
  // if (scene.title !== 'One') {
  //   return imageStack;
  // }

  // Copy the files to stack
  imageStack = [...files];

  // Filter by apartmentNr?
  if (scene.scene_type === 'floorplan') {
    imageStack = filterFilesByApartmentNr(imageStack, apartmentNr);
  }

  // Filter files down to the used ones
  imageStack = filterFilesByScene(imageStack, scene, viewpoint);

  imageStack = filterFilesBySelection(
    imageStack,
    optionFilter(scene.options),
    configuration,
  );

  imageStack = imageStack.filter((file) => (file.unmatchedCode === ''));

  // Sort the imageStack by the layers
  imageStack = sortImageStack(imageStack, viewpoint);

  // console.log(imageStack);
  return imageStack;
}

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
