import React from 'react';
import '../../style/components/Section.scss';

export interface RGContentSectionProps {
  children: any[];
}

export default function RGContentSection(props: RGContentSectionProps) {
  return (
    <div
      className="RGContentSection"
    >
      <div className="content">
        {props.children}
      </div>
    </div>
  );
}
