import { utils } from 'xlsx-js-style';

// -------------------------------------------------------------------------------------------
export function getNumberOfColumns(worksheet) {
  const cellBottomCorner = utils.decode_cell(worksheet['!ref'].split(':')[1]);
  const numberOfColumns = cellBottomCorner.c;
  return numberOfColumns; 
}

// -------------------------------------------------------------------------------------------
export function getNumberOfRows(worksheet) {
  const cellBottomCorner = utils.decode_cell(worksheet['!ref'].split(':')[1]);
  const numberOfRows = cellBottomCorner.r;
  return numberOfRows; 
}

// -------------------------------------------------------------------------------------------
export function randomColor() {
  const letters = 'BCDEF'.split('');
  let color = '';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

// -------------------------------------------------------------------------------------------
// shadeColor("FFDDEE", 40) -> ligher
// shadeColor("FFDDEE", -40) -> darker
export function shadeColor(color, percent) {
  if (color === undefined) return "FFFFFF"; // saftey fallback
  if (color === "FFFFFF") return "FFFFFF"; // cells with white color don't need to be "chessed"

  let R = parseInt(color.substring(0, 2), 16);
  let G = parseInt(color.substring(2, 4), 16);
  let B = parseInt(color.substring(4, 6), 16);

  R = parseInt((R * (100 + percent)) / 100, 10);
  G = parseInt((G * (100 + percent)) / 100, 10);
  B = parseInt((B * (100 + percent)) / 100, 10);

  R = (R < 255) ? R : 255;  
  G = (G < 255) ? G : 255;  
  B = (B < 255) ? B : 255;  

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
  const GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
  const BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

  return RR + GG + BB;
}

// -------------------------------------------------------------------------------------------
export function isHeaderRowOfAnOption(headerContent) {
  /*
    This is crude, but should do the job. Else, a messy second api call would be needed.
    Example option header: Bad - Badlinie | Bad (115).
  */
  if (headerContent.includes(' - ') && headerContent.includes('(') && headerContent.includes(')')) {
    return true;
  }
  return false;
}

// -------------------------------------------------------------------------------------------
export function boldifyRowWithFontSize(worksheet, rowIndex, fontSize) {
  const cellBottomCorner = utils.decode_cell(worksheet['!ref'].split(':')[1]);
  const numberOfColumns = cellBottomCorner.c;
  for (let column = 0; column <= numberOfColumns; column += 1) {
    const cellRef = utils.encode_cell({ c: column, r: rowIndex });
    if (worksheet[cellRef] !== undefined) {
      worksheet[cellRef].s = {
        font: { bold: true, sz: fontSize },
      };
    }
  }
}

// -------------------------------------------------------------------------------------------
export function getWidthsFromHeaderContent(worksheet) {
  const cellBottomCorner = utils.decode_cell(worksheet['!ref'].split(':')[1]);
  const numberOfColumns = cellBottomCorner.c;
  const wsColsWidths = [];
  for (let column = 0; column <= numberOfColumns; column += 1) {
    const cellRef = utils.encode_cell({ c: column, r: 0 });
    if (worksheet[cellRef] !== undefined) {
      if (worksheet[cellRef].v !== undefined) {
        const cellContentLength = worksheet[cellRef].v.length;
        wsColsWidths.push({ wch: cellContentLength });
      }
    }
  }
  return wsColsWidths; 
}

// -------------------------------------------------------------------------------------------
export function findStartIndexesOfOptionsInHeader(worksheet) {
  const startIndexes = [];
  const headers = [];
  const range = utils.decode_range(worksheet['!ref']);
  let C;
  const R = range.s.r; /* start in the first row */
  for (C = range.s.c; C <= range.e.c; C += 1) {
    const cell = worksheet[utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
    let header = '';
    if (cell && cell.t) header = utils.format_cell(cell);
    headers.push(header);
  }

  let lastScene = '';
  for (const [index, header] of headers.entries()) {
    const headerTitle = `${header}`;
    const currentIndex = `${index}`;
    if (isHeaderRowOfAnOption(headerTitle)) {
      const sceneEndIndex = headerTitle.indexOf(' - '); 
      const thisScene = headerTitle.substring(0, sceneEndIndex);
      if (thisScene !== lastScene) {
        lastScene = thisScene; 
        const idx = parseInt(currentIndex, 10);
        startIndexes.push(idx - 1);
      }
    } 
  }
  return startIndexes;
}

// -------------------------------------------------------------------------------------------
export function isEven(n) {
  return n % 2 === 0;
}

// -------------------------------------------------------------------------------------------
// one of the exports has "Bemerkungen" as last column, this one should remain white
// that's what "ignoreLastColumn" is for, the last color is replaced with white

export function colorizeRowsFromOptions(worksheet, ignoreLastColumn = false) {
  const numberOfRows = getNumberOfRows(worksheet);
  const numberOfColumns = getNumberOfColumns(worksheet);
  const foundColorIndexes = findStartIndexesOfOptionsInHeader(worksheet);
  let currentColor = 'FFFFFF';

  // build a column color array with a different color of every option. 
  // below it will be chessified, one lighter, one darker, and so on, per option-color
  const columnColors = [];
  for (let column = 0; column <= numberOfColumns; column += 1) {
    columnColors.push(currentColor);
    if (foundColorIndexes.includes(column)) {
      currentColor = randomColor();
    } 
    if (column === numberOfColumns && ignoreLastColumn) {
      columnColors.pop();
      columnColors.push('FFFFFF');
    } 
  }
  for (let column = 0; column <= numberOfColumns; column += 1) {
    const lightColor = columnColors[column];
    const darkColor = shadeColor(columnColors[column], -5);
    const evenCol = isEven(column);
    for (let row = 1; row <= numberOfRows; row += 1) { // don't colorize top row
      const cellRef = utils.encode_cell({ c: column, r: row });
      if (worksheet[cellRef] && column <= numberOfColumns) {
        worksheet[cellRef].s = {
          fill: {
            fgColor: {
              rgb: evenCol ? lightColor : darkColor,
            },
          },
          border: {
            right: {
              style: "hair",
            },
            left: {
              style: "hair",
            },
            top: {
              style: "hair",
            },
            bottom: {
              style: "hair",
              color: "FFCC00",
            },
            color: {
              rgb: "FFCC00",
            },
          },
        };
      }
    }
  } 
}
