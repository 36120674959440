/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchSelectVariant } from '../data/actions/dispatchConfiguration';

export default function useSelectedVariant(option) {
  const dispatch = useDispatch();
  const configuration = useSelector((state) => state.activeConfiguration);
  const [selection, setSelection] = useState(undefined);

  // Event handlers
  const onSelectVariant = (variantId) => {
    if (selection && selection.selectedVariant !== variantId.toString()) {
      dispatch(dispatchSelectVariant(option.id, variantId));
    }
  };

  // Catch changes of the configuration
  useEffect(() => {
    if (configuration.isSuccess) {
      const foundSelection = configuration.data.optionSelections
        .find((item) => item.option.id === option.id);
      setSelection(foundSelection);
    }
  }, [configuration, option]);

  return {
    selection,
    selectVariant: onSelectVariant,
  };
}
