import React from 'react';

import CartOption from './CartOption';

import '../style/CartSection.scss';

export default function CartSection({
  className,
  section,
  screenSize,
  showPrice = true,
}) {
  // Has content?
  if (section.options.length === 0) {
    return null;
  }

  // Render
  return (
    <div
      className={`CartSection page-break ${className}`}
    >
      <h4>{ section.title }</h4>
      <table>
        <tbody>
          {
            section.options.map((item, index) => (
              <CartOption
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.optionTitle}_${index}`}
                option={item}
                screenSize={screenSize}
                showPrice={showPrice}
              />
            ))
          }
        </tbody>
      </table>
    </div>
  );
}
