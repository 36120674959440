import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { RGButton, RGButtonEnums, RGFormContainer } from 'react-rg-designsystem';

import '../style/PriceDataDownload.scss';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import downloadOrdersTable from '../data/exchange/priceData';

export default function PriceDataDownload() {
  const { dbAuthor } = useParams();
  const [pending, setPending] = useState(false);

  const handleClick = async () => {
    setPending(true);
    // Download ordersTable
    await downloadOrdersTable(dbAuthor);
    setPending(false);
  };

  // Render
  return (
    <div className="PriceDataDownload">
      <RGFormContainer className="priceDataDownload">
        <div className="title">
          <div className={`spinning-wheel ${pending ? 'show' : ''}`} />
          <h4>Preislisten / Bestellungen</h4>
        </div>
        <RGButton
          label="herunterladen"
          emphasis={RGButtonEnums.emphasis.high}
          disabled={pending}
          onClick={handleClick}
        />
      </RGFormContainer>
    </div>
  );
}
