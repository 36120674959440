import React, { useEffect, useRef, useState } from 'react';

import SceneSwiper from './SceneSwiper';
import SceneToolbar from './SceneToolbar';
import SceneView from './SceneView';

import '../style/SceneListDesktop.scss';

export default function SceneListDesktop({
  configuratorId,
  scenes,
  mode,
  isMobile,
}) {
  const swiper = useRef(null);
  const [activeScene, setActiveScene] = useState(scenes[0]);

  // Handle new Scenes
  useEffect(() => {
    if (scenes.length > 0) {
      setActiveScene(scenes[0]);
    }
  }, [scenes]);

  // Event Handlers
  const handleSwiper = (api) => { swiper.current = api; };

  const handleSlideChange = () => {
    if (swiper.current) {
      setActiveScene(scenes[swiper.current.realIndex]);
    }
  };

  // Render
  const sceneComponents = scenes
    .map((item) => (
      <SceneView
        key={item.id}
        scene={item}
        isMobile={isMobile}
      />
    ));

  return (
    <div
      className="SceneListDesktop desktop"
    >
      {
        scenes.length === 1
          ? (
            // Single Scene
            <div className="sceneWrapper noSwiper">
              <SceneToolbar
                configuratorId={configuratorId}
                scene={scenes[0]}
                mode={mode}
                isMobile={isMobile}
              />
              {sceneComponents[0]}
            </div>
          ) : (
            // Multiple Scenes
            <>
              <SceneSwiper
                onSwiper={handleSwiper}
                onSlideChange={handleSlideChange}
                keys={scenes.map((item) => item.id)}
                labels={scenes.map((item) => item.displayed_title)}
              >
                {sceneComponents}
              </SceneSwiper>
              <SceneToolbar
                configuratorId={configuratorId}
                scene={activeScene}
                mode={mode}
                isMobile={isMobile}
              />
            </>
          )
      }
    </div>
  );
}
