/* eslint-disable import/prefer-default-export */

export function getSelectedVariant(option, configuration) {
  // Find the relevant optionSelection in the configuration
  const optionSelection = configuration.optionSelections.find((o) => o.option.id === option.id);

  if (!optionSelection) {
    console.log('The configuration seems to be missing an option!');
    return null;
  }

  // Find the variant in the option
  return option.variants.find((variant) => (
    variant.id.toString() === optionSelection.selectedVariant));
}
