import axios from 'axios';
import paths from '../paths';

export default function dispatchConfiguratorPkg(dbAuthor) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CONFIGURATOR_PKG',
      payload: axios.get(`${paths.API_CONFIGURATOR}/configurator/package/${dbAuthor}`),
    });
  };
}
