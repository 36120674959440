import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Keyboard,
  EffectFade,
  Pagination,
} from 'swiper';

import {
  RGSlideshowNavigation,
} from 'react-rg-designsystem';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import '../style/ViewpointSwiper.scss';

export default function ViewpointSwiper({
  className,
  children,
  keys,
  onSlideChange,
  onSwiper,
  isMobile,
}) {
  const swiperAPI = React.useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Event handlers
  const handleSwiper = (api) => {
    swiperAPI.current = api;
    if (onSwiper) onSwiper(api);
  };

  const handleSlideChange = () => {
    if (swiperAPI.current) {
      setCurrentSlide(swiperAPI.current.realIndex);
    }
    if (onSlideChange) onSlideChange();
  };

  // Render
  return (
    <div
      className={`ViewpointSwiper ${className || ''}`}
    >
      <Swiper
        direction="horizontal"
        pagination={{
          // clickable: true,
        }}
        effect="fade"
        keyboard
        slidesPerView={1}
        loop
        modules={
          isMobile
            ? [Keyboard, EffectFade, Pagination]
            : [Keyboard, EffectFade]
          }
        onSwiper={handleSwiper}
        onSlideChange={handleSlideChange}
      >
        {
          children.map((component, index) => (
            <SwiperSlide
              key={children.length > 1 ? keys[index] : 'single'}
              style={isMobile ? {} : { height: '100vh' }}
            >
              {component}
            </SwiperSlide>
          ))
        }
      </Swiper>
      {
        !isMobile && (
          <RGSlideshowNavigation
            swiperAPI={swiperAPI}
            currentSlide={currentSlide}
            slideCount={children.length}
          />
        )
      }
    </div>
  );
}
