// Central storage of all events that should be tracked by analytics
const analyticsEvents = {
  view: {
    visit: 'view/visit',
  },
  home: {
    loadCode: 'home/loadCode',
  },
  onboarding: {
    start: 'onboarding/start',
    loadCode: 'onboarding/loadCode',
  },
  configure: {
    cart: 'configure/openCart',
  },
  cart: {
    save: 'cart/save',
    print: 'cart/print',
    restart: 'cart/restart',
  },
};
export default analyticsEvents;
