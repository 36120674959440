import axios from 'axios';
import _ from 'lodash';
import { utils } from 'xlsx-js-style';
import exportToXlsx from './xlsx';
import paths, { getConfiguratorPathLive } from '../paths';
import {
  getNumberOfColumns,
  boldifyRowWithFontSize,
  getWidthsFromHeaderContent,
  colorizeRowsFromOptions,
} from './xlsxHelpers';

// -------------------------------------------------------------------------------------------
// Price table export for real estate developers
// -------------------------------------------------------------------------------------------

function variantPricesToTableData(variantPrices, onlyOrdered) {
  // Sort variantPrices by keys into array
  const variantPricesArray = Object.keys(variantPrices)
    .sort()
    .map((key) => variantPrices[key]);

  // Create table data - here the cells get filled!
  const tableData = variantPricesArray.map((variantPrice) => ({
    Scenes: variantPrice.option.scenes,
    IdOption: variantPrice.option.id,
    Option: variantPrice.option.title,
    IdVariant: variantPrice.variant.id,
    Variant: variantPrice.variant.title,
    ...variantPrice.pricesForApartments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.apartmentNr]: !onlyOrdered || curr.isOrdered ? curr.price : '',
      }),
      {},
    ),
  }));

  tableData.sort((a, b) => {
    if (a.Scenes < b.Scenes) return -1;
    if (a.Scenes > b.Scenes) return 1;
    if (a.Option < b.Option) return -1;
    if (a.Option > b.Option) return 1;
    if (a.Variant < b.Variant) return -1;
    if (a.Variant > b.Variant) return 1;
    return 0;
  });
  return tableData;
}

// -------------------------------------------------------------------------------------------
function appendLinksToOrdered(priceTable, dbAuthor, variantPrices) {
  // Get all links by apartment
  const linksByApartment = {};

  Object.keys(variantPrices).forEach((key) => {
    const { pricesForApartments } = variantPrices[key];
    pricesForApartments.forEach((priceForApartment) => {
      if (!linksByApartment[priceForApartment.apartmentNr]) {
        linksByApartment[priceForApartment.apartmentNr] = getConfiguratorPathLive(
          dbAuthor,
          priceForApartment.configuratorId,
          priceForApartment.code,
          priceForApartment.urlParameters,
        );
      }
    });
  });

  // Add a row with the links of the apartments in the second row
  const apartmentLinkRow = {
    Scenes: '',
    IdOption: '',
    Option: '',
    IdVariant: '',
    Variant: '',
    ...linksByApartment,
  };
  return [apartmentLinkRow, ...priceTable];
}

// -------------------------------------------------------------------------------------------
function getTotalAppartmentPrices(priceTable) {
  const defaultColumns = ['Scenes', 'IdOption', 'Option', 'IdVariant', 'Variant'];
  const totalAppartmentPrices = {};
  priceTable.forEach((variantPrice) => {
    Object.keys(variantPrice).forEach((key) => {
      if (!defaultColumns.includes(key)) {
        totalAppartmentPrices[key] = totalAppartmentPrices[key] || 0;
        totalAppartmentPrices[key] += Number(variantPrice[key]) || 0;
      }
    });
  });
  return totalAppartmentPrices;
}

// -------------------------------------------------------------------------------------------
function appendFooterToOrdered(priceTable) {
  // Footer for notes
  const notes = {
    Scenes: '',
    IdOption: '',
    Option: 'Bemerkungen',
    IdVariant: '',
    Variant: '',
  };

  // Footer for total price
  const totalPrices = {
    Scenes: '',
    IdOption: '',
    Option: 'Gesamtpreis',
    IdVariant: '',
    Variant: '',
    ...getTotalAppartmentPrices(priceTable),
  };

  // Append footer to variantPrices
  return [...priceTable, totalPrices, notes];
}

// -------------------------------------------------------------------------------------------
// new worksheet by SN
function applyStylesheetToPricesNew(worksheet) {
  const aWorksheet = { ...worksheet };

  const columnWidths = [
    8, 15, 18, 18, 25, 8, 7, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40,
    40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40,
    40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40,
  ];
  aWorksheet['!cols'] = columnWidths.map((width) => ({ wch: width }));

  const numberOfColumns = getNumberOfColumns(aWorksheet);
  for (let column = 0; column <= numberOfColumns; column += 1) {
    const cellRef = utils.encode_cell({ c: column, r: 0 });
    if (aWorksheet[cellRef]) {
      aWorksheet[cellRef].s = {
        font: {
          bold: true,
          sz: '11',
        },
      };
    }
  }

  colorizeRowsFromOptions(aWorksheet, true);
  return aWorksheet;
}

// -------------------------------------------------------------------------------------------
// new worksheet by SN
function applyStylesheetToOptions(worksheet) {
  const aWorksheet = { ...worksheet };

  const wsColsWidths = getWidthsFromHeaderContent(aWorksheet);
  // adjust first few rows:
  wsColsWidths[0] = { wch: 25 }; // Unit
  wsColsWidths[1] = { wch: 50 }; // Description
  wsColsWidths[2] = { wch: 25 }; // Scene
  aWorksheet['!cols'] = wsColsWidths;
  colorizeRowsFromOptions(aWorksheet);
  boldifyRowWithFontSize(aWorksheet, 0, 12);
  return aWorksheet;
}

// -------------------------------------------------------------------------------------------

function applyExcelStylesheets(worksheet, sheetIndex) {
  if (sheetIndex === 2) return applyStylesheetToPricesNew(worksheet);
  if (sheetIndex === 3) return applyStylesheetToOptions(worksheet);

  // old stuff by SG
  const styledWorksheet = { ...worksheet };
  const cellBottomCorner = utils.decode_cell(styledWorksheet['!ref'].split(':')[1]);
  const numberOfRows = cellBottomCorner.r;
  const numberOfColumns = cellBottomCorner.c;

  const headerRow = 0;
  const headerColumn = 0;
  const headerColumnEnd = utils.decode_cell(styledWorksheet['!ref']).c;
  for (let column = headerColumn; column <= headerColumnEnd; column += 1) {
    const cellRef = utils.encode_cell({ c: column, r: headerRow });
    const isApartmentColumn = column > 3;
    if (styledWorksheet[cellRef]) {
      styledWorksheet[cellRef].s = {
        fill: {
          fgColor: {
            rgb: isApartmentColumn ? 'DAEEF3' : 'DDDDDD',
          },
        },
        font: {
          bold: true,
          sz: '11',
        },
        alignment: {
          horizontal: isApartmentColumn ? 'right' : 'left',
        },
      };
    }
  }

  // Stylize footer rows
  if (sheetIndex === 1) {
    const numberOfFooterRows = sheetIndex === 1 ? 2 : 0;
    const headerTopRow = numberOfRows - numberOfFooterRows;
    // Total price row
    for (let column = 0; column <= numberOfColumns; column += 1) {
      const cellRef = utils.encode_cell({ c: column, r: headerTopRow + 1 });
      if (styledWorksheet[cellRef]) {
        styledWorksheet[cellRef].s = {
          fill: {
            fgColor: {
              rgb: 'DDDDDD',
            },
          },
          font: {
            bold: true,
            sz: '11',
          },
          border: {
            bottom: {
              style: 'thick',
              color: {
                rgb: '000000',
              },
            },
          },
        };
      }
    }
    // Notes row
    for (let column = 0; column <= numberOfColumns; column += 1) {
      const cellRef = utils.encode_cell({ c: column, r: headerTopRow + 2 });
      if (styledWorksheet[cellRef]) {
        styledWorksheet[cellRef].s = {
          font: {
            bold: true,
            sz: '11',
          },
        };
      }
    }
  }

  // Stylize all IDs
  const idColumns = [1, 3];
  for (let i = 0; i < idColumns.length; i += 1) {
    const column = idColumns[i];
    for (let row = 0; row <= numberOfRows; row += 1) {
      const cellRef = utils.encode_cell({ c: column, r: row });
      if (styledWorksheet[cellRef]) {
        // Set font size
        if (!styledWorksheet[cellRef].s) {
          styledWorksheet[cellRef].s = { font: {} };
        }
        if (!styledWorksheet[cellRef].s.font) {
          styledWorksheet[cellRef].s.font = {};
        }
        styledWorksheet[cellRef].s.font.sz = '8';
      }
    }
  }

  // Add right border to column D with the variant names
  const variantColumn = 4;
  for (let row = 0; row <= numberOfRows; row += 1) {
    const cellRef = utils.encode_cell({ c: variantColumn, r: row });
    if (styledWorksheet[cellRef]) {
      // Set border
      if (!styledWorksheet[cellRef].s) {
        styledWorksheet[cellRef].s = { border: {} };
      }
      if (!styledWorksheet[cellRef].s.border) {
        styledWorksheet[cellRef].s.border = {};
      }
      styledWorksheet[cellRef].s.border.right = {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      };
    }
  }

  // Set width of columns
  const columnWidths = [30, 3, 30, 3, 50];
  styledWorksheet['!cols'] = columnWidths.map((width) => ({ wch: width }));

  // Translate column names
  if (sheetIndex === 1 || sheetIndex === 0) {
    styledWorksheet.D1.v = 'Variante';
  }

  return styledWorksheet;
}

// -------------------------------------------------------------------------------------------

async function getOrders(dbAuthor) {
  const errors = [];
  let codes = [];
  let data = [];

  try {
    const response = await axios.get(`${paths.API_CONFIGURATOR}/configurations/codes/${dbAuthor}`);
    codes = response?.data || [];
  } catch (error) {
    console.error(error);
  }

  if (codes.length) {
    const promises = codes.map((code) =>
      axios.get(`${paths.API_CONFIGURATOR}/configurations/orders/${dbAuthor}/${code}`),
    );
    const results = await Promise.all(promises);

    data = results.map((result) => {
      if (result.status !== 200) errors.push(result);
      return result.data;
    });
  } else {
    console.error('No codes found');
  }

  if (errors.length) console.error(errors);

  return data;
}

// -------------------------------------------------------------------------------------------

async function getOptions(dbAuthor) {
  let data = [];

  try {
    const response = await axios.get(
      `${paths.API_CONFIGURATOR}/configurations/options/${dbAuthor}`,
    );
    data = response.data || [];
  } catch (error) {
    console.error(error);
  }
  return data;
}

// -------------------------------------------------------------------------------------------

async function getVariantPrices(dbAuthor) {
  let data = [];

  try {
    const response = await axios.get(`${paths.API_CONFIGURATOR}/configurations/orders/${dbAuthor}`);
    data = response.data || [];
  } catch (error) {
    console.error(error);
  }
  return data;
}

// -------------------------------------------------------------------------------------------

export default async function downloadOrdersTable(dbAuthor) {
  // Get Timestamp
  const date = new Date();
  const timestamp = `${date.getFullYear()}_${
    date.getMonth() + 1
  }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;

  const variantPrices = await getVariantPrices(dbAuthor);
  if (!variantPrices || _.isEmpty(variantPrices)) {
    // eslint-disable-next-line
    alert('Keine Preise gefunden');
    console.error('No prices found');
    return;
  }

  const orders = await getOrders(dbAuthor);
  if (!orders?.length) {
    // eslint-disable-next-line
    alert('Keine Bestellungen gefunden');
    console.error('No orders found');
    return;
  }

  const options = await getOptions(dbAuthor);
  if (!options?.length) {
    // eslint-disable-next-line
    alert('Keine Optionen gefunden');
    console.error('No options found');
    return;
  }

  // Create XLSX file
  exportToXlsx(
    ['Preise', 'Bestellungen', 'Preise Neu', 'Optionen'],
    [
      variantPricesToTableData(variantPrices, false), // Price List
      appendFooterToOrdered(
        appendLinksToOrdered(
          variantPricesToTableData(variantPrices, true),
          dbAuthor,
          variantPrices,
        ),
      ), // Orders
      orders,
      options,
    ],
    `${dbAuthor}-orders-${timestamp}`, // File Name
    applyExcelStylesheets, // Function to apply styles to the table
  );
}
