import {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { loadStateFromStorage, saveStateToStorage } from '../data/localStorage';
import useOfflineMessage from './useOfflineMessage';

export const stages = {
  ONBOARDING: 'ONBOARDING',
  CONFIGURE: 'CONFIGURE',
  CART: 'CART',
  OFFLINE: 'OFFLINE',
};

export default function useConfiguratorStage(configuratorId, isSaved) {
  const defaultStageData = useRef({ data: { stage: stages.ONBOARDING } }).current;
  const [activeStage, setStage] = useState(defaultStageData);
  const offlineMessage = useOfflineMessage(); // configuratorId); not yet needed

  const trySetStage = useCallback((newStage) => {
    // Can only set stage if online
    if (activeStage.data.stage !== stages.OFFLINE) {
      setStage(newStage);
    }
  }, [activeStage.data.stage]);

  // Ensure is online
  useEffect(() => {
    if (offlineMessage) {
      // Does any state disable the whole configurator?
      const isOffline = offlineMessage.all_configurators_unavailable;

      // Go to offline?
      if (isOffline) {
        setStage({ data: { stage: stages.OFFLINE } });
      }
    }
  }, [offlineMessage]);

  // Apply stage change
  const updateStage = useCallback((newStage) => {
    const newState = { data: { stage: newStage } };
    trySetStage(newState);

    saveStateToStorage(
      'activeStages',
      newState,
      configuratorId,
    );
  }, [configuratorId, trySetStage]);

  // Load state from local storage
  useEffect(() => {
    if (configuratorId) {
      const foundState = loadStateFromStorage(
        'activeStages',
        defaultStageData,
        null,
        configuratorId,
      );

      // Update the loaded state
      if (foundState.data) {
        trySetStage(foundState);
      }
    }
  }, [configuratorId, defaultStageData, trySetStage]);

  // Skip onboarding for saved configs
  useEffect(() => {
    if (activeStage.data.stage === stages.ONBOARDING && isSaved) {
      updateStage(stages.CONFIGURE);
    }
  }, [activeStage.data.stage, isSaved, updateStage]);

  // Return stage
  return {
    stages,
    activeStage: activeStage.data.stage,
    setStage: updateStage,
  };
}
