import React from 'react';

import useLocalization from '../hooks/useLocalization';
import Signature from './Signature';

import '../style/ContractSignature.scss';

export default function ContractSignature() {
  const translate = useLocalization();
  return (
    <div
      className="ContractSignature page-break"
    >
      <p className="fine">{translate('contract.disclaimer')}</p>
      <div className="signatures">
        <Signature
          label={translate('contract.signature.buyer')}
        />
        <Signature />
      </div>
    </div>
  );
}
