/* eslint-disable default-param-last */
import baseFetchReducer, { getDefaultFetchState } from './baseFetch';

const presetsReducer = (
  state = getDefaultFetchState(),
  action,
) => baseFetchReducer(
  'PRESETS',
  state,
  action,
);
export default presetsReducer;
