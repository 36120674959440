import React, { useRef } from 'react';

import useLocalization from '../hooks/useLocalization';
import { useSlimSize } from '../hooks/useWindowsResize';
import CartSection from './CartSection';
import CartTotal from './CartTotal';

import '../style/Cart.scss';

export default function Cart({
  cartList,
  forceFullSize,
}) {
  const ref = useRef(null);
  const size = useSlimSize(600, () => ref.current.offsetWidth, forceFullSize);
  const translate = useLocalization();

  // Validate list
  if (!cartList.sections) {
    return null;
  }

  // Render
  return (
    <div
      ref={ref}
      className={`Cart ${size}`}
    >
      <h2>{translate('contract.title')}</h2>
      <h4>{translate('contract.subtitle')}</h4>
      {
        cartList.sections.map((item, index) => (
          <CartSection
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.title}_${index}`}
            section={item}
            screenSize={size}
          />
        ))
      }
      {
        cartList.pricingEnabled && (
          <CartTotal
            priceTotal={cartList.priceTotal}
          />
        )
      }
    </div>
  );
}
