import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { animated } from '@react-spring/web';

import getImageStack from '../data/imageStack';
import { getSceneFolder } from '../data/paths';

import useActiveConfigs from '../hooks/useActiveConfigs';
import useVariantDependencies from '../hooks/useVariantDependencies';
import useImageStackCache, { emptyStack } from '../hooks/useImageCache';
import useLayerAnimation from '../hooks/useLayerAnimation';

import { ReactComponent as ApartmentPlaceholder } from '../theme/Placeholder-Apartment.svg';
import '../style/SceneRenderer.scss';

export default function SceneRenderer({
  className,
  dbAuthor,
  apartmentNr,
  configuratorId,
  scene,
  viewpoint,
  placeholderFloorplan,
  // isVisible,
}) {
  const { activeConfigurator, activeConfiguration } = useActiveConfigs(configuratorId);
  const configuratorFiles = useSelector((state) => state.configuratorFiles);
  const [imageStack, setImageStack] = useState({ ...emptyStack });

  const { filterOptions } = useVariantDependencies(activeConfigurator, activeConfiguration, false);

  // Gather images
  useEffect(() => {
    if (activeConfiguration && configuratorFiles.isSuccess) {
      // Get image stack
      let stack = getImageStack(
        configuratorFiles.data.sceneLayers,
        apartmentNr,
        scene,
        viewpoint,
        activeConfiguration,
        filterOptions,
      );

      // Build full paths
      stack = stack.map((image) => ({
        ...image,
        fullPath: `${process.env.PUBLIC_URL}${getSceneFolder(dbAuthor)}/${image.path}`,
      }));

      // Build key
      const key = stack.map((image) => image.path).join('.');

      // Apply change
      setImageStack({
        key,
        stack,
      });
    }
  }, [activeConfiguration, configuratorFiles]);

  // Cache images
  const cachedStack = useImageStackCache(imageStack);

  // Animation
  const { transitions, styleImage, styleLine } = useLayerAnimation(cachedStack);

  // Validate available data
  if (!activeConfiguration || !configuratorFiles.isSuccess) {
    return null;
  }

  // Placeholder for floorplan when no image was found for the given apartment nr
  if (placeholderFloorplan && cachedStack.stack.length === 0) {
    // eslint-disable-next-line no-console
    console.log(`No image found for the apartment nr "${apartmentNr}" in the link. Please ensure that the name in the file matches this nr.`);
    return (
      <div className="placeholderImage floorplan">
        <ApartmentPlaceholder />
      </div>
    );
  }

  // Render
  return (
    <div
      className={`SceneRenderer ${className || ''}`}
    >
      {
        transitions((style, item) => (
          <>
            <animated.div
              className="stack"
              key={item.key}
              style={styleImage(style)}
            >
              {
                item.stack.map((image) => (
                  <div
                    className="layer"
                    key={image.path}
                  >
                    <img
                      className="sceneImage"
                      style={{ objectPosition: `${viewpoint.image_focus}% 50%` }}
                      src={image.fullPath}
                      alt={image.title}
                    />
                  </div>
                ))
              }
            </animated.div>
            <animated.div
              className="blendLine"
              key="line"
              style={styleLine(style)}
            />
          </>
        ))
      }
    </div>
  );
}
