import React, { useEffect, useState } from 'react';

import '../../style/components/BrandLogo.scss';
import useBrandLogo from '../hooks/useBrandLogo';

export interface RGBrandLogoProps {
  src?: string,
  alt?: string,
  size?: string,  // 'small' | 'medium' | 'large'
}

export default function RGBrandLogo(props: RGBrandLogoProps) {
  const brandLogo = useBrandLogo(props.src, props.alt);

  return (
    <div
      className={`RGBrandLogo ${props.size || 'small'}`}
    >
      <img
        src={brandLogo.src}
        alt={brandLogo.alt}
        draggable="false"
      />
    </div>
  );
}
