/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

import { RGFullSize } from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';
import useLayout from '../hooks/useLayout';
import useConfiguration from '../hooks/useConfiguration';
import useConfiguratorStage from '../hooks/useConfiguratorStage';
import useBrand from '../hooks/useBrand';
import useScanner from '../hooks/useScanner';

import dispatchConfiguratorPkg from '../data/actions/dispatchConfiguratorPkg';
import dispatchConfiguratorFiles from '../data/actions/dispatchConfiguratorFiles';
import dispatchPresets from '../data/actions/dispatchPresets';
import dispatchProjectSettings from '../data/actions/dispatchProjectSettings';

import SceneList from '../components/SceneList';
import CartButton from '../components/CartButton';
import CartScreen from '../components/CartScreen';
import OnboardingScreen from '../components/OnboardingScreen';
import OfflineScreen from '../components/OfflineScreen';

import '../style/Viewer.scss';
import { sendAnalyticsEvent } from '../hooks/useAnalytics';
import analyticsEvents from '../data/analytics';

library.add(faPaperPlane);

// Viewer Page
// ------------
// This page displays a specific Configurator.
// It is the page users will usually interact with.
//
// konfigurator.tend.ch/view/author/configuratorId?nr=HO1.1.01&code=ABC123
// author - the name of the strapi user (part in front of the @)
// configuratorId - the id of the Configurator
// nr - the number of the apartment
// code - the code of the saved Configuration

export default function Viewer() {
  const { dbAuthor, configuratorId } = useParams();
  const activeConfiguratorId = parseInt(configuratorId, 10);
  const { isMobile } = useLayout();

  const configuratorPkg = useSelector((state) => state.configuratorPkg);
  const dispatch = useDispatch();
  const translate = useLocalization();

  const activeConfigurator = configuratorPkg.data.find((configurator) => (
    configurator.id === activeConfiguratorId
  ));

  // Track visit
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const code = searchParams.get('code');
    const nr = searchParams.get('nr');
    sendAnalyticsEvent(analyticsEvents.view.visit, { props: { nr, code } });
  }, [searchParams]);

  // Init data
  useEffect(() => {
    dispatch(dispatchConfiguratorPkg(dbAuthor));
    dispatch(dispatchConfiguratorFiles(dbAuthor));
    dispatch(dispatchPresets(dbAuthor));
    dispatch(dispatchProjectSettings(dbAuthor));

    // Log version
    console.log(navigator.userAgent);
  }, [dbAuthor, dispatch]);

  // Fetch error handling
  useEffect(() => {
    if (configuratorPkg.isError) throw new Error('Error fetching configurator package');
  }, [configuratorPkg]);

  useBrand(dbAuthor, activeConfiguratorId);

  useScanner(activeConfigurator);

  // Init document title
  useEffect(() => {
    if (configuratorPkg.isSuccess) {
      if (activeConfigurator) {
        document.title = `${activeConfigurator.title} - ${translate('tool.title')}`;
      }
    }
  }, [configuratorPkg, activeConfiguratorId, activeConfigurator, translate]);

  // Load savestate and the current stage
  const { isSaved } = useConfiguration();
  const {
    stages,
    activeStage,
    setStage,
  } = useConfiguratorStage(activeConfiguratorId, isSaved);

  // What stage is rendered?
  const stageItems = [];
  if (configuratorPkg.isSuccess) {
    if (activeStage === stages.ONBOARDING) {
      // Display onboarding screens
      stageItems.push(
        <OnboardingScreen
          key="onboarding"
          dbAuthor={dbAuthor}
          isMobile={isMobile}
          configurator={activeConfigurator}
          setStage={setStage}
        />,
      );
    } else if (activeStage === stages.CONFIGURE) {
      // Display SceneList
      stageItems.push(
        <SceneList
          key="SceneList"
          mode="VIEW"
          isMobile={isMobile}
          configurator={activeConfigurator}
          configuratorId={activeConfiguratorId}
        />,
      );

      if (!isMobile) {
        // Display CartButton
        stageItems.push(
          <CartButton
            key="CartButton"
            activeConfiguratorId={activeConfiguratorId}
            onClick={() => { setStage(stages.CART); }}
            translate={translate}
          />,
        );
      }
    } else if (activeStage === stages.CART) {
      // console.log('CartScreen');
      // Display cart (desktop only)
      stageItems.push(
        <CartScreen
          key="CartScreen"
          dbAuthor={dbAuthor}
          activeConfiguratorId={activeConfiguratorId}
          isMobile={isMobile}
          onClose={() => { setStage(stages.CONFIGURE); }}
        />,
      );
    } else if (activeStage === stages.OFFLINE) {
      // Display scan screen
      stageItems.push(
        <OfflineScreen
          key="OfflineScreen"
          isMobile={isMobile}
        />,
      );
    }
  } else {
    // Display loading screen
    stageItems.push(
      <span
        key="loading"
        className="loading"
      >
        lädt...
      </span>,
    );
  }

  // Render
  return (
    <RGFullSize
      className={`Viewer ${isMobile ? 'mobile' : 'desktop'}`}
    >
      {stageItems}
    </RGFullSize>
  );
}
