import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../style/components/Card.scss';

library.add(faTimes);

export interface RGCardProps {
  children: any[],
  className?: string,
  style?: Object,
  onClick?: (e : any) => void,
  onClose?: (e : any) => void,
  blockInteractions?: boolean,
  isDraggable?: boolean,
}

export default function RGCard(props: RGCardProps) {
  // Handlers
  const handleClick = (e : any) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  // Render
  if (props.onClick) {
    return (
      <button
        className={`RGCard ${props.className || ''} interactive allowInteractions`}
        style={props.style}
        type="button"
        onClick={handleClick}
      >
        {props.children}
      </button>
    );
  }
  return (
    <div
      className={`RGCard ${props.className} nonInteractive ${props.blockInteractions ? 'blockInteractions' : 'allowInteractions'}`}
      style={props.style}
    >
      {
        props.onClose
          ? (
            <button
              className="close"
              type="button"
              onClick={props.onClose}
            >
            <FontAwesomeIcon
                icon={faTimes}
                className="faIcon icon on-elevation"
              />
            </button>
          ) : null
      }
      {
        props.isDraggable ? (
          <div className='scrubber' />
        ) : null
      }
      {props.children}
      {/* {
        props.blockInteractions
          ? <div className='blockInteractions' />
          : null
      } */}
    </div>
  );
}
