// const baseUrl = 'http://localhost:3000';
// const baseAPI = 'http://localhost:8082';
const baseUrl = 'https://konfigurator.tend.ch';
const baseAPI = 'https://rgconfiguratorapi.herokuapp.com';

const collectionAPI = `${baseAPI}/admin/content-manager/collectionType/api::`;
const rootPath = 'konfigurator.tend.ch';

const paths = {
  API_ROOT: baseAPI,
  API_CONFIGURATOR: `${baseAPI}/api`,
  EDITOR_CONFIGURATOR_CREATE: `${collectionAPI}configurator.configurator`,
  EDITOR_CONFIGURATOR: `${collectionAPI}configurator.configurator`,
  EDITOR_SCENE_CREATE: `${collectionAPI}/scene.scene`,
  EDITOR_SCENE: `${collectionAPI}scene.scene`,
  EDITOR_OPTION_CREATE: `${collectionAPI}option.option`,
  EDITOR_OPTION: `${collectionAPI}option.option`,
  CONFIGURATION_ENTER: rootPath,
  DEMO_REFERENCE: `https://${rootPath}/view/demo-wilm/20?code=new&nr=HO.1.01`,
  CODE_NEW: '?code=new',
  CODE_PREVIEW: '&preview=1',
};
export default paths;

// -------------------------------------------------------------------------------------------

export function getSceneFolder(dbAuthor) {
  return `/author_data/${dbAuthor}/scenes`;
}

// -------------------------------------------------------------------------------------------

export function parseUrlParameters(urlParameters) {
  if (!urlParameters) {
    return {};
  }

  // Recombine to part of URL
  const asObject = JSON.parse(urlParameters);
  if (!asObject) {
    return '';
  }
  const combined = Object.keys(asObject)
    .map((key) => `${key}=${asObject[key]}`)
    .join('&');
  return `&${combined}`;
}

// -------------------------------------------------------------------------------------------

export function getConfiguratorPathLive(
  dbAuthor,
  configuratorId,
  code,
  urlParameters = '',
) {
  // Build path
  return `${baseUrl}/view/${dbAuthor}/${configuratorId}${
    code ? `?code=${code}` : paths.CODE_NEW
  }${
    parseUrlParameters(urlParameters)
  }`;
}

// -------------------------------------------------------------------------------------------

export function getConfiguratorPathPreview(dbAuthor, configuratorId) {
  return `${baseUrl}/view/${dbAuthor}/${configuratorId}${paths.CODE_NEW}${paths.CODE_PREVIEW}`;
}

// -------------------------------------------------------------------------------------------

export function getConfigurationPath(dbAuthor, configuration) {
  return `${getConfiguratorPathLive(
    dbAuthor,
    configuration.configurator.id,
    configuration.code,
  )}?code=${configuration.code}${configuration.urlParameters}`;
}

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
