import React from 'react';

import useLocalization from '../hooks/useLocalization';
import '../style/ErrorPage.scss';

export default function ErrorPage({ error }) {
  const translate = useLocalization();

  return (
    <div className="ErrorPage">
      <div className="card">
        <h1>{translate('error.title')}</h1>
        <p className="message">{`${translate('error.message')}:`}</p>
        <ul className="suggestions">
          <li>{translate('error.suggestions.1')}</li>
          <li>{translate('error.suggestions.2')}</li>
        </ul>
        {window?.location?.pathname?.includes('edit') ? (
          <p className="error-details">
            <b>{`${translate('error.detailsTitle')}:`}</b>
            <br />
            {error.message}
          </p>
        ) : null}
      </div>
    </div>
  );
}
