import React, { useEffect, useState } from 'react';

// import '../../style/components/OptionalImage.scss';

export const RGOptionalImageEnums : object = {
  size: {
    default: 'default',
    large: 'large',
  },
  emphasis: {
    default: 'default',
    high: 'high',
  },
};

export interface RGOptionalImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: Object;
  placeholderComponent?: React.ReactNode;
  logMissing?: boolean,
}

export default function RGOptionalImage(props: RGOptionalImageProps) {
  const [usesPlaceholder, setUsesPlaceholder] = useState(false);

  // Reset placeholder state if src changes
  useEffect(() => {
    setUsesPlaceholder(false);
  }, [props.src]);

  // Render placeholder
  if (usesPlaceholder) {
    return (
      <div
        className="placeholderImage"
      >
        <p style={{ display: 'none' }}>{`Image failed to load: ${props.src}`}</p>
        { props.placeholderComponent }
      </div>
    );
  }

  // Render image
  return (
    <img
      className={props.className}
      style={props.style}
      src={props.src}
      onError={() => {
        // Log
        if (props.logMissing) {
          console.log(`Image failed to load: ${process.env.PUBLIC_URL + props.src}`);
        }

        // Show placeholder
        setUsesPlaceholder(true);
      }}
      alt={props.alt}
    />    
  );
}
