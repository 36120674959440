import React from 'react';

import paths from '../data/paths';
import VariantList from './VariantList';

import '../style/Option.scss';

export default function Option({
  className,
  option,
  configuratorId,
  mode,
  isMobile,
  selectionMotor,
  forceShowIncluded,
}) {
  // Render
  const optionEditorLink = `${paths.EDITOR_OPTION}/${option.id}`;
  return (
    <div
      className={`Option ${className || ''}`}
    >
      {
        option.displayed_title && (
          <div className="header">
            {
              mode === 'EDIT'
                ? (
                  <a
                    href={optionEditorLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h4>{`${option.displayed_title} (${option.id})`}</h4>
                  </a>
                ) : (
                  <h4>{option.displayed_title}</h4>
                )
            }
            {
              option.description
                ? (
                  <div className="description">
                    <span className={mode === 'EDIT' ? 'warning' : ''}>
                      {option.description}
                    </span>
                  </div>
                ) : null
            }
          </div>
        )
      }
      <VariantList
        mode={mode}
        configuratorId={configuratorId}
        option={option}
        optionEditorLink={optionEditorLink}
        selectionMotor={selectionMotor}
        isMobile={isMobile}
        forceShowIncluded={forceShowIncluded}
      />
    </div>
  );
}
