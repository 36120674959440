/* eslint-disable default-param-last */
import { loadStateFromStorage, saveStateToStorage } from '../localStorage';
import baseFetchReducer, { getDefaultFetchState } from './baseFetch';

const updateLocalConfiguration = (dirtyConfiguration, emptyConfiguration) => {
  // console.log(dirtyConfiguration, emptyConfiguration);
  const newConfiguration = { ...emptyConfiguration };

  // Update the option selections
  for (let i = 0; i < newConfiguration.optionSelections.length; i += 1) {
    const selection = newConfiguration.optionSelections[i];

    // Find the selection in the dirty configuration
    const dirtySelection = dirtyConfiguration.optionSelections.find(
      (s) => s.option.id === selection.option.id,
    );

    // Is there a matching selection in the dirty configuration?
    if (dirtySelection) {
      // Does the selected variant still exist?
      const variantExists = selection.option.variants.findIndex(
        (variant) => variant.id.toString() === dirtySelection.selectedVariant,
      ) !== -1;

      if (variantExists) {
        // Transfer the selection to the new configuration
        selection.selectedVariant = dirtySelection.selectedVariant;
        selection.selectedByUser = dirtySelection.selectedByUser;
      }
    }
  }

  // Update the code
  newConfiguration.code = dirtyConfiguration.code;

  // Restore the preset selection
  newConfiguration.presetSelection = dirtyConfiguration.presetSelection;

  return newConfiguration;
};

const configurationReducer = (
  state = {
    ...getDefaultFetchState(),
    data: undefined,
  },
  action,
) => {
  // console.log(action.type);

  let newState = baseFetchReducer(
    'ACTIVE_CONFIGURATION',
    state,
    action,
  );
  newState = baseFetchReducer(
    'LOCAL_CONFIGURATION',
    newState,
    action,
  );

  // Additional actions
  switch (action.type) {
    // case 'FETCH_ACTIVE_CONFIGURATION_FULFILLED': {
    //   console.log(newState);
    //   break;
    // }
    case 'FETCH_LOCAL_CONFIGURATION_FULFILLED': {
      // Try to load from local storage
      const foundState = loadStateFromStorage(
        'activeConfigurations',
        newState,
        null,
        newState.data.configurator.id,
      );

      // Success?
      const foundValidData = foundState.data !== undefined && foundState.data !== null;
      if (foundValidData) {
        console.log('Local save successfully loaded');
        newState = {
          ...newState,
          data: updateLocalConfiguration(foundState.data, newState.data),
        };
      } else {
        console.log('No local save found, loaded default configuration from API');
      }
      // Else, the default config from the API will be used
      break;
    }
    case 'LINK_CONTACT': {
      console.log('LINK_CONTACT', action.payload);
      console.log('not implemented yet');
      break;
    }
    case 'SELECT_VARIANT': {
      // Validate state is ready for modification
      if (!newState.isSuccess || !newState.data) {
        // console.log('Invalid state for SELECT_VARIANT');
        break;
      }

      // Find modified option
      const modifiedOptionIndex = newState.data.optionSelections.findIndex(
        (selection) => selection.option.id === action.payload.optionId,
      );
      if (modifiedOptionIndex === -1) {
        console.log('Option not found');
        break;
      }

      // Set option to selected variant
      newState
        .data
        .optionSelections[modifiedOptionIndex]
        .selectedVariant = action.payload.variantId.toString();

      // Clear code
      newState
        .data
        .code = 'new';
      break;
    }
    case 'SELECT_PRESET': {
      // Validate state is ready for modification
      if (!newState.isSuccess || !newState.data) {
        break;
      }

      // Find modified option
      const selectedPresetId = action.payload.presetId;
      for (let i = 0; i < newState.data.optionSelections.length; i += 1) {
        const selection = newState.data.optionSelections[i];
        const variantOfPreset = selection.option.variants.find(
          (variant) => variant.presets.findIndex(
            (preset) => preset.id === selectedPresetId,
          ) !== -1,
        );

        if (variantOfPreset) {
          // Set option to selected variant
          newState
            .data
            .optionSelections[i]
            .selectedVariant = variantOfPreset.id.toString();
        }
      }

      // Save state
      newState.data.presetSelection = selectedPresetId;

      // Clear code
      newState
        .data
        .code = 'new';
      break;
    }
    default: {
      break;
    }
  }

  // Save locally
  switch (action.type) {
    case 'FETCH_ACTIVE_CONFIGURATION_SUCCESS':
    case 'SELECT_VARIANT':
    case 'SELECT_PRESET':
    case 'LINK_CONTACT_SUCCESS': {
      saveStateToStorage(
        'activeConfigurations',
        newState,
        newState.data.configurator.id,
      );
      break;
    }
    default: {
      break;
    }
  }

  return newState;
};
export default configurationReducer;
