import { useEffect, useState } from 'react';

export default function useFullHeight() {
  // Track size of window
  const [fullHeight, setFullHeight] = useState(0);
  const resizeListener = () => {
    setFullHeight(window.innerHeight);
  };

  // Add resize listener
  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    resizeListener();

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  
  return fullHeight;
}
