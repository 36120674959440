import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import RGButton, { RGButtonProps } from './Button';
import '../../style/components/Button.scss';

export default function RGButtonSetting(props: RGButtonProps) {
  return (
    <RGButton
      {...props}
      className={`RGButtonSetting RGHeader5 ${props.className}`}
    />
  );
}
