import React from 'react';
// import { useSelector } from 'react-redux';

import {
  RGPlaceholderText,
} from 'react-rg-designsystem';
import { formatPrice } from '../data/localization';

import useLocalization from '../hooks/useLocalization';
import '../style/VariantText.scss';

export default function VariantText({
  variant,
  mode,
  showIncludedLabel,
}) {
  const translate = useLocalization();

  // Validate variant
  if (!variant) {
    return (
      <div
        className="VariantText"
      >
        <RGPlaceholderText exampleText="Monopark Eiche 15, natur" />
      </div>
    );
  }

  // Debug - find variant
  // if (variant.id === 668) {
  //   console.log(variant);
  // }

  // Find price
  let price = showIncludedLabel ? translate('included') : '';
  // let price = '';
  if (variant.price !== 0) {
    price = formatPrice(variant.price);
  }

  // Render
  return (
    <div
      className="VariantText"
    >
      <h5>{variant.title}</h5>
      {
        mode === 'EDIT'// || (presets.isSuccess && presets.data.length > 1)
          ? (
            variant.presets.map((item) => (
              <p
                key={item.id}
              >
                {`${item.title.toUpperCase()}${item.is_default ? ' (default)' : ''}`}
              </p>
            ))
          ) : null
      }
      <p className={mode === 'EDIT' ? 'warning' : ''}>
        {variant.description}
      </p>
      {
        price ? <p>{price}</p> : null
      }
    </div>
  );
}
