import React from 'react';
import '../../style/components/ChipScroller.scss';

export interface RGChipScrollerProps {
  children: any[];
  className?: string,
}

export default function RGChipScroller(props: RGChipScrollerProps) {
  return (
    <div
      className={`RGChipScroller ${props.className || ''}`}
    >
      { props.children }
    </div>
  );
}
