import React from 'react';
import { formatPrice } from '../data/localization';

import '../style/CartOption.scss';

export default function CartOption({ option, screenSize, showPrice }) {
  return (
    <tr
      className={`CartOption ${option.variantPrice ? 'hasPrice' : 'noPrice'}`}
    >
      {
        screenSize === 'full'
          ? (
            <>
              <td>
                <span>
                  {option.optionTitle}
                </span>
              </td>
              <td>
                <span>
                  {option.variantTitle}
                </span>
              </td>
            </>
          ) : (
            <td>
              <h6>{option.optionTitle}</h6>
              <span>{option.variantTitle}</span>
            </td>
          )
      }
      {
        option.variantPrice !== undefined && showPrice && (
          <td>
            <span>
              { formatPrice(option.variantPrice) }
            </span>
          </td>
        )
      }
    </tr>
  );
}
