import React from 'react';

import {
  RGDropdown,
} from 'react-rg-designsystem';

import useSceneDropdown from '../hooks/useSceneSelection';
import SceneToolbar from './SceneToolbar';
import SceneView from './SceneView';

import '../style/SceneListMobile.scss';

export default function SceneListMobile({
  configuratorId,
  scenes,
  mode,
  isMobile,
}) {
  // Manage scene selection
  const {
    activeScene,
    dropdownOptions,
    dropdownSelection,
    handleDropdownChange,
  } = useSceneDropdown(scenes);

  // Render
  return (
    <div className="SceneListMobile mobile">
      <div className={`pageDropdown ${dropdownSelection === 0 ? 'high' : 'default'}-emph`}>
        <RGDropdown
          options={dropdownOptions}
          selection={dropdownSelection}
          exampleText="Mein Wohnraum / Küche"
          emphasis={dropdownSelection === 0 ? 'high' : 'default'}
          onChange={handleDropdownChange}
        />
      </div>
      {
        activeScene
          ? (
            <div className="sceneWrapper">
              <SceneToolbar
                configuratorId={configuratorId}
                scene={activeScene}
                mode={mode}
                isMobile={isMobile}
                sceneIndex={dropdownSelection}
                sceneCount={dropdownOptions.length}
              />
              <SceneView
                scene={activeScene}
                isMobile={isMobile}
              />
            </div>
          ) : null
      }
    </div>
  );
}
