import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dispatchBrand from '../data/actions/dispatchBrands';
import useLocalization from './useLocalization';

// const loadLogo = (url) => {
//   if (url.endsWith('.svg')) {
//     return require(url).default;
//   }
// };

export function useBrandImages() {
  const translate = useLocalization();

  const brand = useSelector((state) => state.brand);
  const [brandImages, setBrandImages] = useState({
    alt: translate('tool.brand'),
    logoOnBrandSurface: undefined,
    logoOnBrightSurface: undefined,
  });

  useEffect(() => {
    if (brand.isSuccess && brand.data.id) {
      if (brand.data.logoOnBrandSurface && brand.data.logoOnBrightSurface) {
        setBrandImages({
          alt: brand.data.title,
          logoOnBrandSurface: brand.data.logoOnBrandSurface.url,
          logoOnBrightSurface: brand.data.logoOnBrightSurface.url,
        });
      }
    }
  }, [brand]);

  return brandImages;
}

const setTypographyStyles = (style, key, data) => {
  if (data) {
    style.setProperty(`--${key}-family`, `${data.family},MatterSQ,-apple-system,sans-serif`);
    style.setProperty(`--${key}-letter-spacing`, `${data.spacingPercentage / 100.0}em`);
    if (data.weight) style.setProperty(`--${key}-weight`, data.weight);
    if (data.lineHeight) style.setProperty(`--${key}-line-height`, `${data.lineHeight}px`);

    switch (data.case) {
      case 'AB': {
        style.setProperty(`--${key}-transform`, 'uppercase');
        break;
      }
      case 'ab': {
        style.setProperty(`--${key}-transform`, 'lowercase');
        break;
      }
      case 'Ab':
      default: {
        style.setProperty(`--${key}-transform`, 'none');
        break;
      }
    }
  }
};

export function applyBrandToStyle(brand, style) {
  // Colors
  if (brand.brandSurfaceColor) {
    style.setProperty('--surface-brand', brand.brandSurfaceColor);
  }

  if (brand.primaryInteractiveColor) {
    style.setProperty('--primary-plus3', brand.primaryInteractiveColor.plus3);
    style.setProperty('--primary-plus2', brand.primaryInteractiveColor.plus2);
    style.setProperty('--primary-plus1', brand.primaryInteractiveColor.plus1);
    style.setProperty('--primary-zero', brand.primaryInteractiveColor.zero);
    style.setProperty('--primary-minus1', brand.primaryInteractiveColor.minus1);
    style.setProperty('--primary-minus2', brand.primaryInteractiveColor.minus2);
    style.setProperty('--primary-minus3', brand.primaryInteractiveColor.minus3);
    style.setProperty('--primary-minus4', brand.primaryInteractiveColor.minus4);
  }

  if (brand.secondaryInteractiveColor) {
    style.setProperty('--secondary-plus3', brand.secondaryInteractiveColor.plus3);
    style.setProperty('--secondary-plus2', brand.secondaryInteractiveColor.plus2);
    style.setProperty('--secondary-plus1', brand.secondaryInteractiveColor.plus1);
    style.setProperty('--secondary-zero', brand.secondaryInteractiveColor.zero);
    style.setProperty('--secondary-minus1', brand.secondaryInteractiveColor.minus1);
    style.setProperty('--secondary-minus2', brand.secondaryInteractiveColor.minus2);
    style.setProperty('--secondary-minus3', brand.secondaryInteractiveColor.minus3);
    style.setProperty('--secondary-minus4', brand.secondaryInteractiveColor.minus4);
  }

  if (brand.successColor) {
    style.setProperty('--success-plus3-field', brand.successColor.plus3);
    style.setProperty('--success-plus1-on-surface', brand.successColor.plus1);
    style.setProperty('--success-zero-base', brand.successColor.zero);
  }

  if (brand.warningColor) {
    style.setProperty('--warning-plus3-field', brand.warningColor.plus3);
    style.setProperty('--warning-plus1-on-surface', brand.warningColor.plus1);
    style.setProperty('--warning-zero-base', brand.warningColor.zero);
  }

  if (brand.errorColor) {
    style.setProperty('--error-plus3-field', brand.errorColor.plus3);
    style.setProperty('--error-plus1-on-surface', brand.errorColor.plus1);
    style.setProperty('--error-zero-base', brand.errorColor.zero);
  }

  // Typography
  setTypographyStyles(style, 'h1', brand.h1);
  setTypographyStyles(style, 'h2', brand.h2);
  setTypographyStyles(style, 'h3', brand.h3);
  setTypographyStyles(style, 'h4', brand.h4);
  setTypographyStyles(style, 'h5', brand.h5);
  setTypographyStyles(style, 'h6', brand.h6);
  setTypographyStyles(style, 'text', brand.text);
  setTypographyStyles(style, 'finePrint', brand.finePrint);
}

export default function useBrand(dbAuthor, configuratorId) {
  const dispatch = useDispatch();
  const brand = useSelector((state) => state.brand);

  useEffect(() => {
    dispatch(dispatchBrand(dbAuthor, configuratorId));
  }, []);

  useEffect(() => {
    if (brand.isSuccess && brand.data.id) {
      applyBrandToStyle(brand.data, document.documentElement.style);
    }
  }, [brand]);
}
