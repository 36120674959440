import React from 'react';

import SceneView from './SceneView';
import CartSection from './CartSection';

import '../style/Floorplan.scss';

export default function Floorplan({ configurator, cartList }) {
  const floorplanScene = configurator.scenes.find((scene) => scene.scene_type === 'floorplan');
  if (!floorplanScene) {
    // console.log('No floorplan found in configurator');
    return null;
  }
  const floorplanCartSections = cartList.sections.filter((section) => section.isFloorplan);
  // console.log('floorplanScene', floorplanScene);
  return (
    <div
      className="Floorplan page-break"
    >
      {
        floorplanCartSections.map((item) => (
          <CartSection
            key={item.title}
            className="floorplan"
            screenSize="full"
            section={item}
            showPrice={false}
          />
        ))
      }
      <SceneView
        className="floorplan"
        scene={floorplanScene}
        isMobile={false}
      />
    </div>
  );
}
