import { useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { useSpring, config } from '@react-spring/web';

export default function useGestureCardScroller(
  deadzone,
  cardDistance,
  maxOffsetX,
  maxCardHeight,
  minCardHeight,
  minOpenDistance,
  onFocusCard,
  onOpenCard,
  draggableObject,
  dragUpEnabled,
  softDragLimits,
) {
  // Calculate
  const maxOffsetY = maxCardHeight - minCardHeight;

  // Track states
  const lockedDirection = useRef(-1);
  const [targetOffsetX, setTargetOffsetX] = useState(0);
  const [targetOffsetY, setTargetOffsetY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  // Calculate the offsets of the card that we aim for
  useGesture(
    {
      onDrag: ({
        movement,
        delta,
      }) => {
        // Deadzone to split into directions
        if (lockedDirection.current === -1) {
          if (!dragUpEnabled || Math.abs(movement[0]) > deadzone) {
            // Lock on X
            lockedDirection.current = 0;
          } else if (Math.abs(movement[1]) > deadzone) {
            // Lock on Y
            lockedDirection.current = 1;
          } else {
            // Still in deadzone
            return;
          }
        }

        // Move
        if (lockedDirection.current === 0) {
          // Apply horizontal offset and clamp
          const clampedOffsetX = Math.max(-softDragLimits, Math.min(maxOffsetX + softDragLimits, targetOffsetX - (delta[0])));
          setTargetOffsetX(clampedOffsetX);
        } else {
          // Apply vertical offset and clamp
          const clampedOffsetY = Math.max(0, Math.min(maxOffsetY, targetOffsetY - (delta[1])));
          setTargetOffsetY(clampedOffsetY);

          // Show details?
          const shouldShowDetauls = clampedOffsetY > minOpenDistance;
          if (showDetails !== shouldShowDetauls) {
            setShowDetails(shouldShowDetauls);
          }
        }
      },
      onDragStart: () => {
        // console.log('Drag start');
        setIsDragging(true);
      },
      onDragEnd: () => {
        // console.log('Drag end');
        // Snap to viewed card
        if (lockedDirection.current === 0) {
          // Snap card to center
          const viewedCard = Math.floor(
            (targetOffsetX + (cardDistance * 0.5)) / cardDistance,
          );
          const snappedOffsetX = viewedCard * cardDistance;
          // console.log(viewedCard, snappedOffsetX);
          // console.log(targetOffsetX, cardDistance);
          setTargetOffsetX(snappedOffsetX);

          // Report
          if (onFocusCard) {
            onFocusCard(viewedCard);
          }
        } else if (lockedDirection.current === 1) {
          // Open or close card
          const isOpen = targetOffsetY > minOpenDistance;
          const newTargetOffsetY = isOpen ? maxOffsetY : 0;
          setTargetOffsetY(newTargetOffsetY);

          // Report
          if (onOpenCard) {
            onOpenCard(isOpen);
          }
        }

        // Unlock direction
        lockedDirection.current = -1;

        // Done!
        setIsDragging(false);
      },
    },
    {
      target: draggableObject,
    },
  );

  // Account for how the UI needs the data
  const uiTargetOffsetX = `${-targetOffsetX}px`;
  const uiTargetOffsetY = `${targetOffsetY + minCardHeight}px`;

  // Animate the offsets smoothly
  const stylesX = useSpring({
    from: {
      transform: 'translateX(0px)',
    },
    to: {
      transform: `translateX(${uiTargetOffsetX})`,
    },
    config: config.stiff,
  });

  const stylesY = useSpring({
    from: {
      minHeight: `${minCardHeight}px`,
    },
    to: {
      minHeight: uiTargetOffsetY,
    },
    config: config.gentle,
  });

  // // Controls
  // const resetScroll = () => {
  //   setTargetOffsetX(0);
  // };

  // Done!
  return {
    stylesX,
    stylesY,
    isDragging,
    showDetails,
    // resetScroll,
  };
}
