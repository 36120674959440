import React from 'react';

import '../style/Signature.scss';

export default function Signature({ label }) {
  return (
    <div
      className="Signature"
    >
      <div className="line" />
      <p className="fine">{label}</p>
    </div>
  );
}
