import { useState, useEffect } from 'react';

import getCartList from '../data/cart';
import useLocalization from './useLocalization';
import useVariantDependencies from './useVariantDependencies';
import useActiveConfigs from './useActiveConfigs';

export default function useCart(configuratorId) {
  // console.log('useCart: ', configuratorId);
  const translate = useLocalization();
  const { activeConfigurator, activeConfiguration } = useActiveConfigs(configuratorId);
  const [cartData, setCartData] = useState({
    activeConfigurator: null,
    activeConfiguration: null,
    cartList: {},
  });

  const { filterScenes, filterOptions } = useVariantDependencies(
    activeConfigurator,
    activeConfiguration,
    // true,
  );

  // Build cart list
  useEffect(() => {
    // console.log('data changed', activeConfigurator, activeConfiguration);
    if (
      activeConfigurator
      && activeConfiguration
    ) {
      // console.log('update cart');
      setCartData({
        cartList: getCartList(
          activeConfigurator,
          activeConfiguration,
          filterScenes,
          filterOptions,
          translate,
        ),
        activeConfigurator,
        activeConfiguration,
      });
    } else {
      // console.log(activeConfiguration, activeConfigurator);
    }
  }, [activeConfigurator, activeConfiguration]);

  return cartData;
}
