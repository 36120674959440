/* eslint-disable no-unused-vars */
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import { useSpring, animated } from '@react-spring/web';

import {
  RGChip,
  RGButtonEnums,
} from 'react-rg-designsystem';

import useCart from '../hooks/useCart';
import useAnalyticsClickHandler from '../hooks/useAnalytics';
import { formatPrice } from '../data/localization';
import analyticsEvents from '../data/analytics';

import '../style/CartButton.scss';

library.add(faShoppingCart);

export default function CartButton({
  activeConfiguratorId,
  onClick,
  translate,
}) {
  const {
    cartList,
  } = useCart(activeConfiguratorId);
  const handleClick = useAnalyticsClickHandler(analyticsEvents.configure.cart, onClick);

  // Animate price changes
  const { animPrice } = useSpring({
    from: { animPrice: 0 },
    to: { animPrice: cartList.priceTotal },
    config: {
      duration: 200,
      clamp: true,
    },
  });

  // Validate data
  if (!cartList || cartList.priceTotal === undefined) {
    // console.log(cartList);
    return null;
  }

  // Render
  return (
    <RGChip
      className="CartButton"
      icon={faShoppingCart}
      label={
        cartList.pricingEnabled
          ? (
            <animated.div>
              { animPrice.to((v) => formatPrice(Math.floor(v))) }
            </animated.div>
          ) : translate('cart.button')
      }
      size={RGButtonEnums.size.large}
      emphasis={RGButtonEnums.emphasis.high}
      onClick={handleClick}
    />
  );
}
