/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export default function useOfflineMessage() {
  const projectSettings = useSelector((state) => state.projectSettings);
  const [searchParams] = useSearchParams();
  const [offlineMessage, setOfflineMessage] = useState({});

  // Ensure is online
  useEffect(() => {
    if (
      searchParams.get('preview') !== '1'
      && projectSettings.isSuccess
      && projectSettings.data
      && projectSettings.data.unavailable_configurators
    ) {
      // Is this configurator offline?
      const unavailableStates = projectSettings.data.unavailable_configurators;

      // Does any state disable the whole configurator?
      const decidingState = unavailableStates.find((state) => state.all_configurators_unavailable);

      // Go to offline?
      if (decidingState) {
        setOfflineMessage(decidingState);
      }
    }
  }, [projectSettings, searchParams]);

  // Return stage
  return offlineMessage;
}
