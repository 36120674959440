import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

export default function SceneSwiper({
  children,
  keys,
  labels,
  onSlideChange,
  onSwiper,
}) {
  return (
    <Swiper
      className="SceneSwiper sceneWrapper"
      direction="vertical"
      pagination={{
        clickable: true,
        renderBullet: (index, className) => (
          `<div class="${className} RGPagination"><span class="label">${labels[index]}</span></div>`
        ),
      }}
      mousewheel
      keyboard
      slidesPerView={1}
      modules={[Pagination, Mousewheel, Keyboard]}
      onSlideChange={onSlideChange}
      onSwiper={onSwiper}
    >
      {
        children.map((component, index) => (
          <SwiperSlide
            key={keys[index]}
            style={{ height: '100%' }}
          >
            {component}
          </SwiperSlide>
        ))
      }
    </Swiper>
  );
}
