import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faCircle,
  faCircleDot,
  faExternalLink,
  faPen,
  faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';

import {
  RGButton,
  RGButtonSetting,
  RGFormContainer,
} from 'react-rg-designsystem';

import SceneList from './SceneList';
import paths, {
  getConfiguratorPathLive,
  getConfiguratorPathPreview,
} from '../data/paths';

import '../style/ConfiguratorList.scss';

library.add(
  faPlus,
  faCircle,
  faCircleDot,
  faExternalLink,
  faPen,
  faUserSecret,
);

export default function ConfiguratorList({ dbAuthor, configuratorPkg, setActiveConfigurator }) {
  const [activeConfiguratorId, setConfigurator] = useState(-1);

  // Set start configurator
  useEffect(() => {
    if (activeConfiguratorId === -1 && configuratorPkg.length > 0) {
      console.log('New package, reset configurator');
      setConfigurator(configuratorPkg[0].id);

      // Send configurator to parent
      if (setActiveConfigurator) {
        setActiveConfigurator(configuratorPkg[0]);
      }
    }
  }, [configuratorPkg, activeConfiguratorId, setActiveConfigurator]);

  // Handle events
  const handleClick = (e, configurator) => {
    // e.preventDefault();
    setConfigurator(configurator);

    // Send configurator to parent
    if (setActiveConfigurator) {
      setActiveConfigurator(configuratorPkg.find((c) => c.id === configurator));
    }
  };

  // Render
  return (
    <div className="ConfiguratorList">
      <RGFormContainer className="configuratorSelection">
        <h4>Wohnungstypen / Links</h4>
        {
          configuratorPkg.map((item) => (
            <RGButtonSetting
              key={item.id}
              label={`${item.title} (${item.id})`}
              icon={activeConfiguratorId === item.id ? faCircleDot : faCircle}
              onClick={(e) => { handleClick(e, item.id); }}
            >
              <a
                className="edit edit"
                aria-label="Edit"
                href={`${paths.EDITOR_CONFIGURATOR}/${item.id}`}
                target="_blank"
                rel="noopener noreferrer"
                title="edit configurator"
              >
                <FontAwesomeIcon
                  icon={faPen}
                />
              </a>
              <a
                className="link preview"
                aria-label="Link Preview"
                href={getConfiguratorPathPreview(dbAuthor, item.id)}
                target="_blank"
                rel="noopener noreferrer"
                title="preview configurator - even when it's disabled"
              >
                <FontAwesomeIcon
                  icon={faUserSecret}
                />
              </a>
              <a
                className="link live"
                aria-label="Link Live"
                href={getConfiguratorPathLive(dbAuthor, item.id)}
                target="_blank"
                rel="noopener noreferrer"
                title="live configurator"
              >
                <FontAwesomeIcon
                  icon={faExternalLink}
                />
              </a>
            </RGButtonSetting>
          ))
        }
        <RGButton
          className="create"
          label="Configurator"
          icon={faPlus}
          href={paths.EDITOR_CONFIGURATOR_CREATE}
          target="_blank"
        />
      </RGFormContainer>
      <div className="shownConfigurator">
        {
          activeConfiguratorId !== -1
            ? (
              <SceneList
                configuratorId={activeConfiguratorId}
                configurator={
                  configuratorPkg.find((item) => item.id === activeConfiguratorId)
                }
                mode="EDIT"
              />
            ) : null
        }
      </div>
    </div>
  );
}
