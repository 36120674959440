import React from 'react';
import { formatPrice } from '../data/localization';
import useLocalization from '../hooks/useLocalization';

import '../style/CartTotal.scss';

export default function CartTotal({ priceTotal }) {
  const translate = useLocalization();

  return (
    <div
      className="CartTotal"
    >
      <div className="total">
        <h4>{translate('cart.total.title')}</h4>
        <h4>{formatPrice(priceTotal)}</h4>
      </div>
      <p className="fine">{translate('cart.total.disclaimer')}</p>
    </div>
  );
}
