import axios from 'axios';
import paths from '../paths';

export default function dispatchBrand(dbAuthor, configuratorId) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_BRAND',
      payload: axios.get(`${paths.API_CONFIGURATOR}/brand/${dbAuthor}/${configuratorId}`),
    });
  };
}
