/* eslint-disable no-unused-vars */
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import {
  RGBrandHeader,
  RGContainerButton,
} from 'react-rg-designsystem';

import useCart from '../hooks/useCart';
import { useBrandImages } from '../hooks/useBrand';

import ConfiguratedScenes from './ConfiguratedScenes';
import ConfigurationOverview from './ConfigurationOverview';

import '../style/CartScreen.scss';

library.add(faTimes);

export default function CartScreen({
  dbAuthor,
  activeConfiguratorId,
  isMobile,
  onClose,
}) {
  const {
    activeConfigurator,
    activeConfiguration,
    cartList,
  } = useCart(activeConfiguratorId);

  const {
    alt,
    logoOnBrandSurface,
  } = useBrandImages();

  // Validate data
  if (!activeConfigurator) {
    return null;
  }

  // Render
  return (
    <div className={`CartScreen ${isMobile ? 'mobile' : 'desktop'}`}>
      <RGBrandHeader
        src={logoOnBrandSurface}
        alt={alt}
      />
      <RGContainerButton
        className="close"
        icon={faTimes}
        onClick={onClose}
      />
      <ConfiguratedScenes
        dbAuthor={dbAuthor}
        activeConfigurator={activeConfigurator}
      />
      <ConfigurationOverview
        dbAuthor={dbAuthor}
        activeConfiguration={activeConfiguration}
        cartList={cartList}
      />
    </div>
  );
}
