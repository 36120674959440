import React, { useState } from 'react';

import {
  RGButton,
  RGButtonEnums,
  RGBrandLogo,
  RGSidebarLeftList,
  RGBrandHeader,
} from 'react-rg-designsystem';

import { stages } from '../hooks/useConfiguratorStage';
import useLocalization from '../hooks/useLocalization';
import { useBrandImages } from '../hooks/useBrand';
import useAnalyticsClickHandler from '../hooks/useAnalytics';
import analyticsEvents from '../data/analytics';

import ToolbarHeader from './ToolbarHeader';
import OpenCodeScreen from './OpenCodeScreen';
import MoodImage from './MoodImage';
import PresetOption from './PresetOption';

import '../style/OnboardingScreen.scss';

// -------------------------------------------------------------------------------------------

function OnboardingScreenMobile({
  commonComponents,
  brandSrc,
  brandAlt,
  hasPresets,
  translate,
}) {
  const [page, setPage] = useState(0);

  // Render bottom panel
  let bottomPanel;
  if (hasPresets) {
    if (page === 0) {
      // First Page, with presets
      bottomPanel = (
        <div className="bottomPanel">
          {commonComponents.header}
          <div className="buttons">
            {commonComponents.loadCodeButton}
            <RGButton
              className="continue"
              label={translate('continue')}
              onClick={() => setPage(1)}
              emphasis={RGButtonEnums.emphasis.high}
            />
          </div>
        </div>
      );
    } else {
      // Second Page, without presets
      bottomPanel = (
        <div className="bottomPanel">
          {commonComponents.presetOption}
          <div className="buttons">
            <RGButton
              className="back"
              label={translate('back')}
              onClick={() => setPage(0)}
              emphasis={RGButtonEnums.emphasis.high}
            />
            {commonComponents.startButton}
          </div>
        </div>
      );
    }
  } else {
    // No presets
    bottomPanel = (
      <div className="bottomPanel">
        {commonComponents.header}
        <div className="buttons">
          {commonComponents.loadCodeButton}
          {commonComponents.startButton}
        </div>
      </div>
    );
  }

  return (
    <div
      className="OnboardingScreen mobile"
    >
      {
        page === 0
          ? (
            <RGBrandHeader
              src={brandSrc}
              alt={brandAlt}
            />
          ) : (
            <div className="page designLine">
              <h3>{translate('onboarding.designLine.cta')}</h3>
            </div>
          )
      }
      {commonComponents.moodImage}
      {bottomPanel}
    </div>
  );
}

// -------------------------------------------------------------------------------------------

function OnboardingScreenDesktop({
  commonComponents,
  brandSrc,
  brandAlt,
}) {
  return (
    <div
      className="OnboardingScreen desktop"
    >
      <RGSidebarLeftList>
        <RGBrandLogo
          src={brandSrc}
          alt={brandAlt}
        />
        {commonComponents.header}
        {commonComponents.presetOption}
        <div className="buttons">
          {commonComponents.startButton}
          {commonComponents.loadCodeButton}
        </div>
      </RGSidebarLeftList>
      {commonComponents.moodImage}
    </div>
  );
}

// -------------------------------------------------------------------------------------------

export default function OnboardingScreen({
  dbAuthor,
  isMobile,
  configurator,
  setStage,
}) {
  const translate = useLocalization();
  const {
    alt,
    logoOnBrandSurface,
    logoOnBrightSurface,
  } = useBrandImages();
  const [showOpenCodePopup, setOpenCodePopup] = useState(false);
  const handleOpenCode = useAnalyticsClickHandler(analyticsEvents.onboarding.loadCode);
  const handleStart = useAnalyticsClickHandler(
    analyticsEvents.onboarding.start,
    () => setStage(stages.CONFIGURE),
  );

  // Validate Input
  if (!configurator || !configurator.scenes || configurator.scenes.length === 0) {
    return <p style={{ color: 'white' }}>{ translate('noScenes') }</p>;
  }

  // Build common elements
  const commonComponents = {
    header: (
      <ToolbarHeader
        label={translate('onboarding.title')}
        description={translate('onboarding.description')}
        mode="VIEW"
      />
    ),
    startButton: (
      <RGButton
        className="start"
        label={translate('start')}
        onClick={handleStart}
        emphasis={RGButtonEnums.emphasis.high}
      />
    ),
    loadCodeButton: (
      <RGButton
        className="loadCode"
        label={translate('onboarding.openCode.button.minimal')}
        onClick={() => setOpenCodePopup(true)}
        emphasis={RGButtonEnums.emphasis.default}
      />
    ),
    moodImage: (
      <MoodImage
        dbAuthor={dbAuthor}
        scene={
          configurator.scenes
            .filter((scene) => scene.is_main_scene)[0]
          || configurator.scenes[0]
        }
        isMobile={isMobile}
      />
    ),
    presetOption: (
      <div className="optionContainer">
        <PresetOption
          configurator={configurator}
          isMobile={isMobile}
          translate={translate}
        />
      </div>
    ),
  };

  // Render
  return (
    <>
      {
        isMobile
          ? (
            <OnboardingScreenMobile
              isMobile={isMobile}
              commonComponents={commonComponents}
              hasPresets={configurator.presetOption.length > 0}
              brandSrc={logoOnBrandSurface}
              brandAlt={alt}
              translate={translate}
            />
          ) : (
            <OnboardingScreenDesktop
              isMobile={isMobile}
              commonComponents={commonComponents}
              brandSrc={logoOnBrightSurface}
              brandAlt={alt}
            />
          )
      }
      {
        showOpenCodePopup && (
          <OpenCodeScreen
            onClose={() => setOpenCodePopup(false)}
            onConfirm={handleOpenCode}
          />
        )
      }
    </>
  );
}
