import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import '../../style/components/Button.scss';

export const RGButtonEnums : object = {
  size: {
    default: 'default',
    large: 'large',
  },
  emphasis: {
    default: 'default',
    high: 'high',
  },
};

export interface RGButtonProps {
  className?: string;
  label?: string;
  icon?: IconDefinition;
  children?: React.ReactNode;

  iconIsRight?: boolean;
  emphasis?: string;
  background?: string;
  size?: string;

  onClick?: any;
  style?: Object;
  disabled?: any;
  href?: string;
  target?: string;
  download?: string;
}

export default function RGButton(props: RGButtonProps) {
  const anchor = useRef<HTMLAnchorElement>(null);
  const handleClick = (e : any) => {
    
    // Try to click the anchor link
    const currentAnchor = anchor.current;
    if (props.href && currentAnchor) {
      // console.log(`Opening link: ${props.href}`);
      currentAnchor.click();
    }

    // Try to trigger the callback
    if (props.onClick) {
      props.onClick(e);
    }
  }
  return (
    <button
      className={`
        RGButton
        ${props.className}
        ${props.emphasis ? props.emphasis : 'default'}-emph
        on-${props.background ? props.background : 'elevation'}
        ${props.size ? props.size : 'default'}-size
        `}
      type="button"
      onClick={handleClick}
      disabled={props.disabled}
      style={props.style}
    >
      {
        props.href &&
          <a
            ref={anchor}
            href={props.href}
            download={props.download}
            rel="noopener noreferrer"
            target={props.target ? props.target : '_blank'}
          />
      }
      {
        // Disabled Overlay
        props.disabled
          ? <></>
          : <div className="Disabled" />
      }
      {
        // Icon
        props.icon
        ? (
          <FontAwesomeIcon
            icon={props.icon}
            className={`faIcon icon on-elevation ${props.iconIsRight ? 'right' : 'left' }`}
          />
        ) : null
      }
      {
        // Label
        props.label ? (
          <h4 className={`OnPrimary ${props.icon ? 'hasIcon' : ''}`}>
            {props.label}
          </h4>
         ) : null
      }
      {
        // Children
        props.children ? (
          <div className="children">
            {props.children}
          </div>
        ) : null
      }
    </button>
  );
}
