import { useEffect, useState } from 'react';

export default function useWindowsResize(onResize) {
  // Listen to window resize events
  useEffect(() => {
    onResize();

    // Subscribe to changes
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
}

export function useSlimSize(width, getter, forceFullSize) {
  const [size, setSize] = useState('full');

  // Listen to window resize events
  useWindowsResize(() => {
    setSize(
      forceFullSize || getter() >= width
        ? 'full' : 'slim',
    );
  });

  return size;
}
