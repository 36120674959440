import React from 'react';
import '../../style/components/PlaceholderText.scss';

export interface RGPlaceholderTextProps {
  exampleText: string;
}

export default function RGPlaceholderText(props: RGPlaceholderTextProps) {
  // Validate input
  if (!props.exampleText) {
    return null;
  }

  // Attributes
  const characterWidth = 8;
  const characterVariance = 4;

  // Get propperties of the text
  const lines = props.exampleText.split('\n');
  // const lineCount = lines.length;
  // const characterCount = lines[0].length;

  // Render
  return (
    <div
      className="RGPlaceholderText"
    >
      {
        lines.map((line) => {
          const lineWidth = (characterWidth * line.length)
            + Math.floor(characterWidth * characterVariance * Math.random());
          return (
            <div
              key={line}
              className="line"
              style={{ width: `${lineWidth}px` }}
            />
          );
})
      }
    </div>
  );
}
