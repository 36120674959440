import { useEffect, useState } from 'react';

export default function useSceneDropdown(scenes) {
  const [activeScene, setActiveScene] = useState(null);
  const [dropdownSelection, setDropdownSelection] = useState(-1);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  // React to changes in the configurator
  useEffect(() => {
    if (scenes) {
      // Get sorted scenes

      // Update dropdown options
      setDropdownOptions(
        scenes.map((item) => ({
          value: item.id,
          label: item.displayed_title,
        })),
      );

      // Is it the first scene?
      if (activeScene === null) {
        setActiveScene(scenes[0]);
        setDropdownSelection(0);
      } else {
        // Find the scene in the new dropdown options
        const newSelection = dropdownOptions.findIndex((item) => item.value === activeScene.id);
        if (newSelection !== -1) {
          // Select it
          setDropdownSelection(newSelection);
        } else {
          // Select the first scene
          setActiveScene(scenes[0]);
          setDropdownSelection(0);
        }
      }
    }
  }, [scenes, activeScene, dropdownOptions]);

  // Event handlers
  function handleDropdownChange(index, sceneId) {
    // Find the selected scene
    const selectedScene = scenes.find((item) => item.id === sceneId);

    // Update the active scene
    setActiveScene(selectedScene);
    setDropdownSelection(index);
  }

  return {
    activeScene,
    dropdownOptions,
    dropdownSelection,
    handleDropdownChange,
  };
}
