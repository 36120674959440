// Time should always be displayed consistently across the app.
// This could be extended with localization.

export function formatDate(date) {
  return new Intl.DateTimeFormat(
    'de-CH',
    {
      month: '2-digit',
      year: '2-digit',
      day: '2-digit',
    },
  )
    .format(date);
}

export function formatTime(date) {
  return date.toLocaleString('de-CH', {
    hour: '2-digit',
    minute: '2-digit',
    seconds: '2-digit',
  });
}
