import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import {
  RGButton,
} from 'react-rg-designsystem';

import paths from '../data/paths';

import SceneToolbar from './SceneToolbar';
import SceneView from './SceneView';

import '../style/SceneListDesktop.scss';

library.add(faPlus);

export default function SceneListEditor({
  configuratorId,
  scenes,
  mode,
  isMobile,
}) {
  return (
    <div
      className="SceneListEditor desktop editor"
    >
      {
        scenes.map((scene) => (
          <div
            className="sceneWrapper noSwiper"
            key={scene.id}
          >
            <SceneToolbar
              configuratorId={configuratorId}
              scene={scene}
              mode={mode}
              isMobile={isMobile}
            />
            <SceneView
              scene={scene}
            />
          </div>
        ))
      }
      <RGButton
        className="create"
        label="Scene"
        icon={faPlus}
        href={paths.EDITOR_SCENE_CREATE}
        target="_blank"
      />
    </div>
  );
}
