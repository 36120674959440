import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { RGOptionalImage } from 'react-rg-designsystem';

import { getSceneFolder } from '../data/paths';

import SceneRenderer from './SceneRenderer';
import ViewpointSwiper from './ViewpointSwiper';

import { ReactComponent as RoomPlaceholder } from '../theme/Placeholder-Room.svg';
import { ReactComponent as ApartmentPlaceholder } from '../theme/Placeholder-Apartment.svg';
import '../style/SceneView.scss';

export default function SceneView({ className, scene, isMobile }) {
  const { dbAuthor, configuratorId } = useParams();
  const [searchParams] = useSearchParams();
  const apartmentNr = searchParams.get('nr');
  const configuratorFiles = useSelector((state) => state.configuratorFiles);

  // Get Scene Bases
  const getSceneBasePath = (sceneFolderName) => {
    // Try to find the base image
    if (
      configuratorFiles.isSuccess
      && configuratorFiles.data.sceneBases
      && configuratorFiles.data.sceneBases.length
    ) {
      const foundFile = configuratorFiles.data.sceneBases.find((sceneBase) => sceneBase.path.includes(`${sceneFolderName}/base.`));
      if (foundFile) {
        // Found it!
        // console.log('Found base image for scene', foundFile.path);
        return foundFile.path;
      }
    }

    // This won't work, but the placeholder will be shown anyways
    // console.log('No base image found for scene', sceneFolderName);
    return `${sceneFolderName}/unknown`;
  };

  // Get Viewpoint Components
  const viewpointComponents = [];
  if (scene.scene_type === 'floorplan') {
    if (!apartmentNr) {
      // Is floorplan without file
      // No Apartment Nr - Show Placeholder
      // eslint-disable-next-line no-console
      console.log('No apartment nr found in the link. Please add a parameter like this to the link: &nr=Abc.12.3');
      viewpointComponents.push(
        <div
          className="placeholderImage floorplan"
          key={`${scene.id}.placeholder`}
        >
          <ApartmentPlaceholder />
        </div>,
      );
    } else if (scene.viewpoints.length > 0) {
      // Is floorplan with file
      viewpointComponents.push(...scene.viewpoints
        .map((viewpoint) => (
          scene.options.length === 0
            ? (
              <RGOptionalImage
                key={viewpoint.id}
                className="sceneImage floorplan"
                style={{ objectPosition: `${viewpoint.image_focus}% 50%` }}
                src={`${process.env.PUBLIC_URL}${getSceneFolder(dbAuthor)}/${getSceneBasePath(viewpoint.folder_name)}`}
                alt="Scene"
                placeholderComponent={(<ApartmentPlaceholder className="placeholderImage" />)}
              />
            ) : (
              <SceneRenderer
                key={viewpoint.id}
                className="floorplan"
                dbAuthor={dbAuthor}
                apartmentNr={apartmentNr}
                configuratorId={configuratorId}
                scene={scene}
                viewpoint={viewpoint}
                placeholderFloorplan={(<ApartmentPlaceholder className="placeholderImage" />)}
              />
            )
        )));
    }
  } else if (scene.viewpoints.length === 0) {
    // No Viewpoints - Show Placeholder
    viewpointComponents.push((
      <RoomPlaceholder
        key="placeholder"
        className="placeholderImage"
        alt="Placeholder"
      />
    ));
  } else {
    // Has viewpoints
    viewpointComponents.push(...scene.viewpoints
      .map((viewpoint) => (
        <Fragment key={viewpoint.id}>
          <RGOptionalImage
            className="sceneImage default"
            style={{ objectPosition: `${viewpoint.image_focus}% 50%` }}
            src={`${process.env.PUBLIC_URL}${getSceneFolder(dbAuthor)}/${getSceneBasePath(viewpoint.folder_name)}`}
            alt="Scene"
            placeholderComponent={(<RoomPlaceholder className="placeholderImage" />)}
          />
          <SceneRenderer
            className="default"
            dbAuthor={dbAuthor}
            configuratorId={configuratorId}
            scene={scene}
            viewpoint={viewpoint}
          />
        </Fragment>
      )));
  }

  // Render
  return (
    <ViewpointSwiper
      className={`SceneView ${className || ''}`}
      keys={scene.viewpoints.map((viewpoint) => `${viewpoint.id}.${viewpoint.folder_name}`)}
      isMobile={isMobile}
    >
      {viewpointComponents}
    </ViewpointSwiper>
  );
}
