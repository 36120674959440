/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { RGFormContainer, RGFormSegment } from 'react-rg-designsystem';

import paths from '../data/paths';
import useLocalization from '../hooks/useLocalization';
import '../style/ImageStructure.scss';

// -------------------------------------------------------------------------------------------

function SegmentHeader(translate, successes, errors, textKey) {
  return (
    <div className="SegmentHeader">
      {
        errors <= 0
          ? (
            <>
              <h4 className="headerText success">
                {successes}
              </h4>
              <h4 className="headerText">
                {translate(`folderStructure.${textKey}.all`)}
              </h4>
            </>
          ) : (
            <>
              <h4 className="headerText error">
                {errors}
              </h4>
              <h4 className="headerText">
                {translate(`folderStructure.${textKey}.missing`)}
              </h4>
            </>
          )
      }
    </div>
  );
}

// -------------------------------------------------------------------------------------------

function FeedbackExplanation({ translate, textKey }) {
  return (
    <div className="FeedbackExplanation">
      <p>{translate(`folderStructure.${textKey}.description`)}</p>
    </div>
  );
}

// -------------------------------------------------------------------------------------------

function ImageMatches({ translate, sceneLayers }) {
  // Validate files
  let foundSuccesses = 0;
  let foundErrors = 0;
  const validationData = [];

  sceneLayers.forEach((layer) => {
    // Count
    if (layer.unmatchedCode === '') {
      foundSuccesses += 1;
    } else {
      foundErrors += 1;
    }

    // Gather Data
    validationData.push({
      path: layer.path,
      unmatched: layer.unmatchedCode,
      // unmatched: layer.unmatchedCode === '' ? translate('ok') : layer.unmatchedCode,
    });
  });

  // Render
  const label = SegmentHeader(translate, foundSuccesses, foundErrors, 'matches');
  return (
    <RGFormSegment
      className="ImageMatches"
      label={label}
      // startOpen
    >
      <RGFormContainer>
        <FeedbackExplanation
          textKey="matches"
          translate={translate}
        />
        {
          validationData.map((file) => (
            <div className={`validationEntry ${file.unmatched === '' ? '' : 'error'}`} key={file.path}>
              <span>{file.path}</span>
              <span>{file.unmatched}</span>
            </div>
          ))
        }
      </RGFormContainer>
    </RGFormSegment>
  );
}

// -------------------------------------------------------------------------------------------

function VariantDuplicates({ translate, options }) {
  // Validate files
  let foundSuccesses = 0;
  let foundErrors = 0;
  const validationData = [];

  // Index variants by file name
  const variantsByName = {};
  options.forEach((option) => {
    option.variants.forEach((variant) => {
      // Skip if no file name
      if (!variant.file_name.includes('null0')) {
        // Is there already a filename?
        if (variantsByName[variant.file_name] === undefined) {
          variantsByName[variant.file_name] = [{ option, variant }];
        } else {
          variantsByName[variant.file_name].push({ option, variant });
        }
      }
    });
  });

  // Find names that include eachother
  const namesList = Object.keys(variantsByName);
  namesList.forEach((a) => {
    namesList.forEach((b) => {
      if (
        a !== b
        && Object.prototype.hasOwnProperty.call(variantsByName, a)
        && Object.prototype.hasOwnProperty.call(variantsByName, b)
        && (a.includes(b) || b.includes(a))) {
        // Merge lists
        variantsByName[`${a}, ${b}`] = [].concat(variantsByName[a], variantsByName[b]);
        delete variantsByName[a];
        delete variantsByName[b];
        // console.log('Merged', a, b);
      }
    });
  });

  // Find duplicates
  Object.keys(variantsByName).forEach((fileName) => {
    const variants = variantsByName[fileName];
    // Count
    if (variants.length <= 1) {
      foundSuccesses += 1;
    } else {
      foundErrors += variants.length;
    }

    // Gather Data
    if (variants.length > 1) {
      variants.forEach((variant) => {
        const newEntry = {
          option: variant.option.title,
          optionId: variant.option.id,
          variant: variant.variant.title,
          path: fileName,
        };

        // Add unique errors
        if (validationData.findIndex((entry) => (
          entry.optionId === newEntry.optionId
          && entry.path === newEntry.path
        )) === -1
        ) {
          validationData.push(newEntry);
        }
      });
    }
  });

  // Render
  const label = SegmentHeader(translate, foundSuccesses, foundErrors, 'duplicates');
  return (
    <RGFormSegment
      className="VariantImages"
      label={label}
      // startOpen
    >
      <RGFormContainer>
        <FeedbackExplanation
          textKey="duplicates"
          translate={translate}
        />
        {
          validationData.map((file) => (
            <div className="validationEntry error" key={`${file.optionId}_${file.path}`}>
              <div>
                <a
                  href={`${paths.EDITOR_OPTION}/${file.optionId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.option}
                </a>
                <span>{`: ${file.variant}`}</span>
              </div>
              <span>{file.path}</span>
            </div>
          ))
        }
      </RGFormContainer>
    </RGFormSegment>
  );
}
// -------------------------------------------------------------------------------------------

function VariantImages({ translate, sceneLayers, options }) {
  // Validate files
  let foundSuccesses = 0;
  let foundErrors = 0;
  const validationData = [];

  // Find Images for all options variants
  options.forEach((option) => {
    option.variants.forEach((variant) => {
      // Look for a matching file
      const foundFile = sceneLayers.find((file) => (
        file.assignments
        && file.assignments.findIndex((assignment) => (
          // Found option match?
          assignment.option.id.toString() === option.id.toString()
          // Found variant match?
          && assignment.variants.findIndex((assignmentVariant) => (
            assignmentVariant.id.toString() === variant.id.toString()
          )) !== -1
        )) !== -1
      ));

      // Count
      if (foundFile) {
        foundSuccesses += 1;
      } else {
        foundErrors += 1;
      }

      // Gather Data
      validationData.push({
        option: option.title,
        optionId: option.id,
        variant: variant.title,
        path: variant.file_name,
        hasFile: !!foundFile,
      });
    });
  });

  // Render
  const label = SegmentHeader(translate, foundSuccesses, foundErrors, 'images');
  return (
    <RGFormSegment
      className="VariantImages"
      label={label}
      // startOpen
    >
      <RGFormContainer>
        <FeedbackExplanation
          textKey="images"
          translate={translate}
        />
        {
          validationData.map((file) => (
            <div className={`validationEntry ${file.hasFile ? '' : 'error'}`} key={`${file.optionId}_${file.path}`}>
              <div>
                <a
                  href={`${paths.EDITOR_OPTION}/${file.optionId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.option}
                </a>
                <span>{`: ${file.variant}`}</span>
              </div>
              <span>{file.path}</span>
            </div>
          ))
        }
      </RGFormContainer>
    </RGFormSegment>
  );
}

// -------------------------------------------------------------------------------------------

function VariantThumbnails({ translate, thumbnails, options }) {
  // Validate files
  let foundSuccesses = 0;
  let foundErrors = 0;
  const validationData = [];

  // Find Thumbnails for all options variants
  options.forEach((option) => {
    option.variants.forEach((variant) => {
      const foundFile = thumbnails.find((file) => (
        file.assignments
        && file.assignments.findIndex((assignment) => (
          assignment.option.id.toString() === option.id.toString()
          && assignment.variant.id.toString() === variant.id.toString()
        )) !== -1
      ));

      // Count
      if (foundFile) {
        foundSuccesses += 1;
      } else {
        foundErrors += 1;
      }

      // Gather Data
      validationData.push({
        option: option.title,
        optionId: option.id,
        variant: variant.title,
        path: `${option.folder_name}_${variant.file_name}`,
        hasFile: !!foundFile,
      });
    });
  });

  // Render
  // console.log(validationData);
  const label = SegmentHeader(translate, foundSuccesses, foundErrors, 'thumbs');
  return (
    <RGFormSegment
      className="VariantThumbnails"
      label={label}
      // startOpen
    >
      <RGFormContainer>
        <FeedbackExplanation
          textKey="thumbs"
          translate={translate}
        />
        {
          validationData.map((file) => (
            <div className={`validationEntry ${file.hasFile ? '' : 'error'}`} key={`${file.optionId}_${file.path}`}>
              <div>
                <a
                  href={`${paths.EDITOR_OPTION}/${file.optionId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.option}
                </a>
                <span>{`: ${file.variant}`}</span>
              </div>
              <span>{file.path}</span>
            </div>
          ))
        }
      </RGFormContainer>
    </RGFormSegment>
  );
}

// -------------------------------------------------------------------------------------------

function BaseImages({ translate, sceneBases, scenes }) {
  // Validate files
  let foundSuccesses = 0;
  let foundErrors = 0;
  const validationData = [];

  // Find files for scenes
  scenes.forEach((scene) => {
    if (!scene.scene_type || scene.scene_type === 'default') {
      if (scene.viewpoints && scene.viewpoints.length > 0) {
        scene.viewpoints.forEach((viewpoint, index) => {
          // Is it valid?
          const isSuccess = sceneBases.findIndex((file) => (
            file.scene
            && file.viewpoint
            && file.scene.id.toString() === scene.id.toString()
            && file.viewpoint.id.toString() === viewpoint.id.toString()
          )) !== -1;

          // Count
          if (isSuccess) {
            foundSuccesses += 1;
          } else {
            foundErrors += 1;
          }

          // Gather Data
          validationData.push({
            scene: scene.title,
            viewpoint: index,
            folder_name: viewpoint.folder_name || 'missing folder_name',
            hasFile: isSuccess,
          });
        });
      } else {
        // No viewpoints
        foundErrors += 1;
        validationData.push({
          scene: scene.title,
          viewpoint: 'no viewpoints',
          folder_name: 'missing viewpoint',
          hasFile: false,
        });
      }
    }
  });

  // Render
  const label = SegmentHeader(translate, foundSuccesses, foundErrors, 'baseImages');
  return (
    <RGFormSegment
      className="BaseImages"
      label={label}
      // startOpen
    >
      <RGFormContainer>
        <FeedbackExplanation
          textKey="baseImages"
          translate={translate}
        />
        {
          validationData.map((file) => (
            <div className={`validationEntry ${file.hasFile ? '' : 'error'}`} key={`${file.scene}.${file.viewpoint}`}>
              <span>{`${file.scene} (${file.viewpoint})`}</span>
              {
                !file.hasFile && (
                  <span>{`${file.folder_name}/base`}</span>
                )
              }
            </div>
          ))
        }
      </RGFormContainer>
    </RGFormSegment>
  );
}

// -------------------------------------------------------------------------------------------

export default function ImageStructure() {
  const translate = useLocalization();
  const files = useSelector((state) => state.configuratorFiles);

  // Try to read data
  if (!files.isSuccess) {
    return null;
  }

  return (
    <div className="ImageStructure">
      <VariantDuplicates
        translate={translate}
        options={files.data.verification.options}
      />
      <VariantImages
        translate={translate}
        sceneLayers={files.data.sceneLayers}
        options={files.data.verification.options}
      />
      <VariantThumbnails
        translate={translate}
        thumbnails={files.data.thumbnails}
        options={files.data.verification.options}
      />
      <BaseImages
        translate={translate}
        sceneBases={files.data.sceneBases}
        scenes={files.data.verification.scenes}
      />
      <ImageMatches
        translate={translate}
        sceneLayers={files.data.sceneLayers}
      />
    </div>
  );
}
