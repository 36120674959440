/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RGPlaceholderText from './PlaceholderText';
// import { RGButtonEnums } from './Button';

import '../../style/components/Dropdown.scss';

library.add(
  faChevronDown,
  faChevronUp,
  faCircleChevronDown,
);

export interface RGDropdownProps {
  options: any[],
  selection: number, // index of selected option
  exampleText: string,
  emphasis?: string;
  className? : string;
  onChange?: (index: number, value: number) => void,
  onState?: (state: string) => void,
}

export default function RGDropdown(props: RGDropdownProps) {
  const [dropdownSelection, selectDropdown] = useState(props.options[props.selection]);
  const [dropdownState, setDropdownState] = useState('CLOSED');

  // Update attributes
  useEffect(() => {
    // Evaluate the state of the data
    const hasData : boolean = props.selection !== null
      && props.options.length > 0;

    const dataIsDifferent : boolean = !dropdownSelection
      || dropdownSelection.value !== `${props.selection}`;

    // Select something new?
    if (hasData && dataIsDifferent) {
      selectDropdown(props.options[props.selection]);
    }
  }, [props.selection, props.options]);
  
  // Validate valid selection
  if (!dropdownSelection) {
    // console.log(`Dropdown has invalid selection: ${
    //   JSON.stringify(props.selection)} - ${
    //     JSON.stringify(props.options)}`);
    return (
      // <div
      //   className={`Select header placeholder`}
      // />
      <div
        className={`Select header placeholder`}
      >
        <RGPlaceholderText exampleText={props.exampleText} />
      </div>
    );
  }

  // Events
  const handleChange = (selectedOption : any) => {
    selectDropdown(selectedOption);
    if (props.onChange) {
      const newSelectedValue = parseInt(selectedOption.value, 10);
      const newSelectedIndex = props.options.findIndex((option) => option.value === newSelectedValue);
      props.onChange(
        newSelectedIndex,
        newSelectedValue,
      );
    }
  };
  const handleOpen = () => {
    if (props.onState) {
      props.onState('OPEN');
    }
    setDropdownState('OPEN');
  };
  const handleClose = () => {
    if (props.onState) {
      props.onState('CLOSE');
    }
    setDropdownState('CLOSED');
  };

  // Custom components
  const { emphasis } = props;
  const DropdownIndicator = (props : any) => {
    const iconClosed = emphasis === 'high' ? faCircleChevronDown : faChevronDown;
    return (
      components.DropdownIndicator && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon={dropdownState === 'OPEN' ? faChevronUp : iconClosed}
          className="faIcon icon on-elevation"
        />
      </components.DropdownIndicator>
      )
    );
  };

  // Render
  return (
    <Select
      className={`
        RGDropdown Select header
        ${props.emphasis ? props.emphasis : 'default'}-emph
        ${props.className || ''}
        `}
      classNamePrefix="tend-select"
      components={{ DropdownIndicator }}
      value={dropdownSelection}
      options={props.options}
      onChange={handleChange}
      onMenuOpen={handleOpen}
      onMenuClose={handleClose}
      // menuIsOpen={true}
    />
  );
}
