import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import RGButton from './Button';
import '../../style/components/Chip.scss';

export interface RGChipProps {
  className?: string;
  label?: string;
  icon?: IconDefinition;

  emphasis?: string;
  background?: string;
  size?: string;

  onClick?: any;
  disabled?: any;
  href?: string;
  target?: string,
  download?: string;
}

export default function RGChip(props: RGChipProps) {
  return (
    <RGButton
      className={`RGChip ${props.className}`}
      label={props.label}
      icon={props.icon}

      emphasis={props.emphasis}
      background={props.background}
      size={props.size}

      onClick={props.onClick}
      disabled={props.disabled}
      href={props.href}
      target={props.target}
      download={props.download}
    />
  );
}
