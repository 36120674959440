/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  RGCard,
} from 'react-rg-designsystem';

import useSelectedPreset from '../hooks/useSelectedPreset';
import useCart from '../hooks/useCart';

import Option from './Option';

import '../style/PresetOption.scss';

export default function PresetOption({
  configurator,
  isMobile,
}) {
  const { configuratorId } = useParams();
  const {
    cartList,
  } = useCart(configurator.id);

  const option = {
    title: '',
    variants: configurator.presetOption.map((preset) => ({
      ...preset,
      price: cartList.pricingEnabled ? cartList.priceTotal : 0,
    })),
  };

  return (
    <RGCard
      className="PresetOption"
    >
      <Option
        option={option}
        configuratorId={configuratorId}
        isMobile={isMobile}
        selectionMotor={useSelectedPreset}
        mode="VIEW"
        forceShowIncluded
      />
    </RGCard>
  );
}
