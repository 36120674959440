/* eslint-disable default-param-last */
import baseFetchReducer, { getDefaultFetchState } from './baseFetch';

const configuratorFilesReducer = (
  state = getDefaultFetchState(),
  action,
) => baseFetchReducer(
  'CONFIGURATOR_FILES',
  state,
  action,
);
export default configuratorFilesReducer;
