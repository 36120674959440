import axios from 'axios';
import paths from '../paths';

export default function dispatchProjectSettings(dbAuthor) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_PROJECT_SETTINGS',
      payload: axios.get(`${paths.API_CONFIGURATOR}/project-settings/author/${dbAuthor}`),
    });
  };
}
