/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function useThumbnail(variant) {
  const configuratorFiles = useSelector((state) => state.configuratorFiles);
  const [thumbnail, setThumbnail] = useState(undefined);
  const { dbAuthor } = useParams();

  // Load thumbnails
  useEffect(() => {
    if (configuratorFiles.isSuccess) {
      if (variant.isPreset) {
        setThumbnail(configuratorFiles.data.thumbnails
          .find((file) => file.presetId === variant.id));
      } else {
        setThumbnail(configuratorFiles.data.thumbnails
          .find((file) => file.assignments && file.assignments.findIndex(
            (assignment) => assignment.variant.id.toString() === variant.id.toString(),
          ) !== -1));
      }
    }
  }, [configuratorFiles, variant.id, variant.isPreset]);

  return { thumbnail, dbAuthor };
}
