import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import RGFormContainer from './FormContainer';
import RGContainerButton from './ContainerButton';

import '../../style/components/Popup.scss';
import RGBackdrop from './Backdrop';

library.add(faTimes);

export interface RGPopupProps {
  className?: string;
  icon?: IconDefinition;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  onClose?: (e : any) => void;
}

export default function RGPopup(props: RGPopupProps) {
  return (
    <div
      className={`RGPopup ${props.className || ''}`}
    >
      <RGBackdrop
        onClose={ props.onClose }
      />
      <RGFormContainer>
        {
          props.icon &&
            <FontAwesomeIcon
              icon={props.icon}
              className="faIcon icon on-elevation"
            />
        }
        {
          props.title &&
            <h3>{ props.title }</h3>
        }
        {
          props.description &&
            <p>{ props.description }</p>
        }
        { props.children }
        {
          props.onClose &&
            <RGContainerButton
              icon={faTimes}
              onClick={props.onClose}
            />
        }
      </RGFormContainer>
    </div>
  );
}
