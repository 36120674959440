import React from 'react';
import '../../style/components/Backdrop.scss';

export interface RGBackdropProps {
  onClose?: (e : any) => void;
}

export default function RGBackdrop(props: RGBackdropProps) {
  if (props.onClose) {
    return (
      <button
        className="RGBackdrop backdrop"
        type="button"
        onClick={ props.onClose }
      />
    );
  } else {
    return (
      <div
        className="RGBackdrop backdrop"
      />
    );
  }
}
