import React from 'react';
import RGFilter from './Filter';
import '../../style/components/Sidebar.scss';

export interface RGSidebarTop1DesktopProps {
  filterValue: string;
  filterLabel: string;
  children: any;
}

export default function RGSidebarTop1Desktop(props: RGSidebarTop1DesktopProps) {
  const rgLogo = require('../../theme/tend_logo_black.svg').default as string;

  return (
    <div
      className="RGSidebar top1 desktop"
    >
      <img
        className="RGLogo"
        src={rgLogo}
        alt="tend logo"
        draggable="false"
      />
      <RGFilter
        label={props.filterLabel}
        value={props.filterValue}
      />
      { props.children }
    </div>
  );
}
